import React from 'react';
import { pageTitle } from '../PageTitle';
import HeaderOne from '../Header/HeaderOne';
import FooterOne from '../Footer/FooterOne';
import BreadCrumb from '../BreadCrumb';
import { Link } from 'react-router-dom';
import blogBg from "../../assets/images/news/inside-6.jpg";
import b2 from "../../assets/images/news/s-DScience.jpg";
import b3 from "../../assets/images/news/s-dataPipeline.png";
import b4 from "../../assets/images/news/s-dataIngestion.jpg";
import b5 from "../../assets/images/news/sidebar-2.jpg";
import b6 from "../../assets/images/news/sidebar-3.jpg";
import ServiceFooter from '../Footer/ServiceFooter';


const DataPlatformBlog = () => {
    pageTitle('Data Platform Blog');

    const handleSubmitOne = (event) => {
        event.preventDefault();
    };
    const handleSubmitTwo = (event) => {
        event.preventDefault();
    };

    return (

        <>
            <HeaderOne></HeaderOne>
            {/* <BreadCrumb></BreadCrumb> */}
            <div className="blog__details see__pad">
                <div className="auto-container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-12 col-md-12">
                            <div className="blog__details__content ">
                                <div className="blog__image p_relative">
                                    <img src={blogBg} alt="" />
                                </div>
                                <div className="blog__inner__box">
                                    <h3 className="blog__title"><span>Cloudera Data Platform</span></h3>
                                    <div className="blog__details__text">
                                        <p>Cloudera Data Platform (CDP) is a data cloud built for the enterprise. With CDP, businesses manage and secure the end-to-end data lifecycle – collecting, enriching, analyzing, experimenting, and predicting with their data – to drive actionable insights and data-driven decision making.</p>
                                        <p>The most valuable and transformative business use cases require multi-stage analytic pipelines to process enterprise data sets. CDP empowers businesses to unlock value from large-scale, complex, distributed, and rapidly changing data, enabling them to compete in the age of digital transformation.</p>

                                        <h3 className="blog__title"><span>An Integrated Data Platform</span></h3>
                                        <p>Cloudera Data Platform (CDP) provides an integrated data platform that creates agility along lines of business while facilitating efficiency and security within IT, enabling the entire organization to be more productive. As organizations adapt quickly to changing business needs, CDP delivers mission-critical advantages:</p>

                                        <ul>
                                            <li>Designed for data engineers, data scientists, BI analysts, developers, and enterprise IT</li>
                                            <li>Cloud-native, simple to use, and automatically secure by design</li>
                                            <li>Best-in-class analytics and integrated data lifecycle</li>
                                            <li>Self-serve and custom analytics</li>
                                            <li>Operates on public cloud and on-premises environments</li>
                                        </ul>

                                        <p>CDP enables enterprise IT to balance agility and security, delivering the capabilities of an enterprise data cloud.</p>

                                        <h3 className="blog__title"><span>Key Capabilities</span></h3>

                                        <ul>
                                            <li><strong>ANY CLOUD</strong> - Run on multiple public clouds or on-premises.</li>
                                            <li><strong>DATA LIFECYCLE INTEGRATION</strong> - Utilize multiple analytics on the same data, eliminating costly and inefficient data silos.</li>
                                            <li><strong>SECURITY AND GOVERNANCE</strong> - Maintain strict data security, governance, and control across all environments.</li>
                                            <li><strong>OPEN</strong> - Ensure interoperability and avoid vendor lock-in with open-source support.</li>
                                        </ul>
<br></br>
                                        <h3 className="blog__title"><span>Why CDP?</span></h3>

                                        <ul>
                                            <li><strong>Lower TCO</strong> - An integrated data lifecycle that eliminates ongoing orchestration and development work.</li>
                                            <li><strong>Stronger Security</strong> - Secure by design, with comprehensive security capabilities across the data lifecycle.</li>
                                            <li><strong>Run Anywhere</strong> - Supports hybrid cloud and multi-cloud environments with consistent user experience and flexibility.</li>
                                        </ul>
                                    </div>
                                </div>



                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-12">
                            <div className="sidebar__content__box">

                                <div className="single__sidebar__box">
                                    <div className="title">
                                        <h3>Latest posts</h3>
                                    </div>
                                    <div className="sidebar__blog__post">
                                        <ul className="blog__post">
                                            <li>
                                                <div className="inner">
                                                    <div className="img__box">
                                                        <img src={b2} alt="Awesome" />
                                                    </div>
                                                    <div className="title__box">
                                                        {/* <div className="date"><i className="icon-15"></i>26 July 2023</div> */}
                                                        <h4><Link to="/blog-datascience">Data Science</Link></h4>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="inner">
                                                    <div className="img__box">
                                                        <img src={b3} alt="Awesome" />
                                                    </div>
                                                    <div className="title__box">
                                                        {/* <div className="date"><i className="icon-15"></i>26 July 2023</div> */}
                                                        <h4><Link to="/blog-dataPipeline">Data Pipeline</Link></h4>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="inner">
                                                    <div className="img__box">
                                                        <img src={b4} alt="Awesome" />
                                                    </div>
                                                    <div className="title__box">
                                                        {/* <div className="date"><i className="icon-15"></i>26 July 2023</div> */}
                                                        <h4><Link to="/blog-data-ingestion">Data Ingestion</Link></h4>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="single__sidebar__box">
                                    <div className="title">
                                        <h3>Categories</h3>
                                    </div>
                                    <div className="sidebar-categories">
                                        <ul className="sidebar-categories-box">
                                            <li><Link to="#"><i className="icon-17"></i> Data Storage and Management</Link></li>
                                            <li><Link to="#"><i className="icon-17"></i> Data Processing and Computation</Link></li>
                                            <li><Link to="#"><i className="icon-17"></i>Data Integration and Ingestion</Link></li>
                                            <li><Link to="#"><i className="icon-17"></i>Data Security and Governance</Link></li>
                                            <li><Link to="#"><i className="icon-17"></i>Data Analytics and Visualization</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ServiceFooter/>
        </>
    );
};

export default DataPlatformBlog;
