import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { pageTitle } from "../PageTitle";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import { Link, useLocation } from "react-router-dom";
import service from "../../assets/images/resource/admin.jpg";
import basebg from "../../assets/images/resource/base.png";
import g1 from "../../assets/images/resource/Administrator-new.png";

const Cloud = () => {
  pageTitle("Cloudera Data Administrator Course");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    setFormData({
      name: "",
      email: "",
      subject: "",
      phone: "",
      message: "",
    });
  };

  const servicesData = [
    { id: 5, title: "RPA & AI", slug: "/rpa-training" },
    {
      id: 1,
      title: "Cloudera Data Engineering",
      slug: "/data-engineering-course",
    },
    { id: 2, title: "Cloudera Machine Learning", slug: "/machine-learning" },
    { id: 3, title: "Cloudera Data Analyst", slug: "/data-analyst" },
    { id: 4, title: "Cloudera Administrator", slug: "/cloud" },
    { id: 6, title: "Just enough Python", slug: "/python" },
  ];

  const renderServices = () => {
    return servicesData.map((service) => (
      <li key={service.id}>
        <Link to={service.slug}>{service.title}</Link>
      </li>
    ));
  };

  return (
    <>
      <Helmet>
        {/* SEO Meta Tags */}
        <title>Cloudera Data Administrator Course | SIW</title>
        <meta
          name="description"
          content="Learn Cloudera Data Platform management with our Administrator course. Gain hands-on skills for Cloudera Manager and CDP."
        />
        <meta
          name="keywords"
          content="Cloudera Data Administrator, Cloudera Manager, CDP Course, Cloudera Training"
        />
        <meta name="author" content="SIW" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <HeaderOne />

      <section className="page__title p_relative">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${g1})` }}
        ></div>
        <div className="auto-container">
          <div className="content__box p_relative">
            {/* <ul className="bread__crumb">
                    <li><Link to="/">Home</Link></li>
                    {pathnames.map((name, index) => (
                    <li key={index}>
                        <span> -</span>
                        <Link to={`/${pathnames.slice(0, index + 1).join('/')}`}>{name}</Link>
                    </li>
                    ))}
                </ul> */}
            {/* <h1 className="title">{pathnames.length > 0 ? pathnames[pathnames.length - 1] : 'Home'}</h1> */}
          </div>
        </div>
      </section>
      <div className="service__details p_relative">
        <div className="auto-container">
          <div className="row">
            <div className="col-xl-8 col-lg-12 col-md-12">
              <div className="service__right">
                <figure>
                  <img src={service} alt="Service" />
                </figure>

                <h2>
                  <span>Overview</span>
                </h2>

                <p>
                  Cloudera Data Platform (CDP) is a fully integrated edge-to-AI
                  product set, and Cloudera Administrator plays a crucial role
                  in its management. Cloudera Manager is specifically designed
                  as the DevOps tooling for building and managing Cloudera Data
                  Platform. This four-day hands-on course provides a detailed
                  explanation, comprehensive theory, key skills, and recommended
                  practices for successful platform administration.
                </p>

                <p>
                  Upon completion of this course, a Cloudera Administrator will
                  learn the full range of functionality and capabilities of
                  Cloudera Manager in supporting Cloudera Data Platform. The
                  course delivers an in-depth explanation and essential skills
                  to become highly productive with Cloudera Manager and Cloudera
                  Data Platform. Cloudera Manager is a full-featured and mature
                  DevOps tool that is used to install, configure, operate,
                  troubleshoot, report, and upgrade CDP. Many Cloudera
                  Administrators only utilize a fraction of the capabilities
                  built into Cloudera Manager. This course teaches the
                  architecture, deployment, configuration, logging, reporting,
                  REST API, and much more. Additionally, it provides references
                  for architecture and recommended practices used by enterprises
                  around the globe.
                </p>

                <h2>
                  <span>What You’ll Learn</span>
                </h2>

                <p>
                  This course teaches installation, configuration, operation,
                  management, incident resolution, and troubleshooting of
                  Cloudera Manager, specifically for the role of a Cloudera
                  Administrator.
                </p>

                <h2>
                  <span>What to Expect</span>
                </h2>

                <p>
                  This course is an entry point for aspiring CDP Administrators
                  and any CDP Administrators not well familiar with the full
                  range of Cloudera Manager capabilities. Students must have a
                  background in Linux and knowledge of SQL. Students must have
                  access to the Internet to reach the classroom environments,
                  which are located on Amazon Web Services.
                </p>

                <br></br>

                <h3>
                  <span>Course Details</span>
                </h3>

                <ul className="course-details-list">
                  <li>
                    <h5>
                      {" "}
                      <b> 1. Cloudera Manager Design </b>{" "}
                    </h5>
                    - Topics covered: Roles of a CDP Administrator, Getting
                    aboard the classroom environment.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 2. Cloudera Manager Architecture </b>{" "}
                    </h5>{" "}
                    - Topics covered: Cloudera Manager server-agent
                    architecture, CDP architecture.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 3. Deploying Cloudera Manager </b>{" "}
                    </h5>
                    - Topics covered: Creating an air gap repository, Install
                    Cloudera Manager.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>4. Configuring Auto-TLS </b>{" "}
                    </h5>
                    - Topics covered: Basic TLS, Creating the jssecacerts file,
                    Deploying CM as a Root CA.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 5. Problem Management </b>{" "}
                    </h5>
                    - Topics covered: Role logs and diagnosis, Cloudera Blog,
                    Community, and Support.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 6. Bundling </b>{" "}
                    </h5>
                    - Topics covered: Creating Support bundles, Limiting Support
                    bundles, Redaction rules.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 7. Reporting </b>{" "}
                    </h5>
                    - Topics covered: Create custom dashboards, Create custom
                    reports.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 8. Configuring Kerberos </b>{" "}
                    </h5>{" "}
                    - Topics covered: Basic Kerberos, Deploying Kerberos.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 9. Deploy CDP Services </b>{" "}
                    </h5>
                    - Topics covered: Install Cloudera Manager Agents, Install
                    CDP Runtime.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 10. Navigating within Cloudera Manager </b>{" "}
                    </h5>
                    - Topics covered: Navigating Cloudera Manager, Search with
                    Cloudera Manager.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 11. Operations with Cloudera Manager</b>{" "}
                    </h5>
                    - Topics covered: Restarting Cloudera Manager, Understanding
                    and restarting supervisord, Automating restarts of cluster
                    services.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 12. Performance Tuning </b>{" "}
                    </h5>
                    - Topics covered: Cloudera Manager tuning properties, Tuning
                    Cloudera Manager.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 13. Resource Management </b>{" "}
                    </h5>
                    - Topics covered: Managing Roles and Templates, Adding
                    Workers, Decommissioning and removing workers.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 14. YARN Queues and Jobs</b>{" "}
                    </h5>{" "}
                    - Topics covered: Install and Configure YARN Queues, Running
                    and Managing jobs.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 15. Managing Services</b>{" "}
                    </h5>
                    - Topics covered: Identifying and installing Parcels, Add
                    and remove CDP services.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 16. Configuration Management </b>{" "}
                    </h5>
                    - Topics covered: Configuration changes to properties,
                    Configuring Role Groups.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 17. Incident Management </b>{" "}
                    </h5>
                    - Topics covered: Configuring monitoring, alerting and
                    notification, Rolling back changes.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 18. Logging </b>{" "}
                    </h5>
                    - Topics covered: Managing Role logs, Managing log rotation.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>19. Upgrading Cloudera Manager CDP </b>{" "}
                    </h5>
                    - Topics covered: Backup Cloudera Manager and the scm
                    database, Upgrading Cloudera Manager.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 20. Managing Clusters with the REST API </b>{" "}
                    </h5>
                    - Topics covered: REST API documentation, REST API Swagger
                    UI, REST API command line, REST API shell script.
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-12">
              <div className="service__details__left">
                <div className="sidebar__widget">
                  <div className="all__service">
                    <h4>All Courses</h4>
                  </div>

                  <ul className="service__details__1__cat">
                    {renderServices()}
                  </ul>
                </div>

                <div
                  className="sidebar__widget_network"
                  style={{ backgroundImage: `url(${basebg})` }}
                >
                  <h3>Contact Us For Any Require</h3>

                  <div className="services__icon">
                    <i className="icon-19"></i>
                  </div>

                  <p>Need help? Call us:</p>

                  <h4>+65 90059743</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterOne />
    </>
  );
};

export default Cloud;
