import React from "react";

import { pageTitle } from "../PageTitle";

import HeaderOne from "../Header/HeaderOne";

import FooterOne from "../Footer/FooterOne";

import BreadCrumb from "../BreadCrumb";

import { Link } from "react-router-dom";

import blogBg from "../../assets/images/resource/Spark (1).png";

import blogBg1 from "../../assets/images/resource/ARCHITECTURE.png";

// import blogBg2 from "../../assets/images/news/wp-ai.jpg";

import b2 from "../../assets/images/news/s-Rdd.jpg";

import b3 from "../../assets/images/news/s-DataPlatform.jpg";
import b4 from "../../assets/images/news/s-DScience.jpg";
import b5 from "../../assets/images/news/react.png";

import b6 from "../../assets/images/news/big-data 1.png";

const SparkBlog = () => {
  pageTitle("Spark");

  const handleSubmitOne = (event) => {
    event.preventDefault();
  };

  const handleSubmitTwo = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <HeaderOne></HeaderOne>

      {/* <BreadCrumb></BreadCrumb> */}

      <div className="blog__details see__pad">
        <div className="auto-container">
          <div className="row">
            <div className="col-xl-8 col-lg-12 col-md-12">
              <div className="blog__details__content ">
                <div className="blog__image p_relative">
                  <img src={blogBg} alt="" />
                </div>

                <div className="blog__inner__box">
                  <h3 className="blog__title"><span>Apache Spark</span></h3>

                  <div className="blog__details__text">
                    <p>
                      Apache Spark is an open-source computing framework engine
                      that is used for analytics, graph processing, and machine
                      learning.
                    </p>

                    <p>
                      Spark has a real-time processing framework that processes
                      large amount of data every day. Spark is used not only in
                      IT companies, it can used by various industries like
                      healthcare, banking, stock exchanges, and more.
                    </p>

                    <p>
                      The primary reason for its popularity is that Spark
                      architecture is well-layered and integrated with other
                      libraries, making it easier to use.
                    </p>

                    <p>
                      Spark is a master/slave architecture and has two main
                      daemons:
                    </p>

                    <ul>
                      <li>The master daemon</li>

                      <li>The worker daemon</li>
                    </ul>

                    <br></br>

                    <p>
                      The two important aspects of a Spark architecture are the
                      Spark ecosystem and RDD. An Apache Spark ecosystem
                      contains Spark SQL, Scala, MLib, and the core Spark
                      component.
                    </p>

                    <p>
                      <strong>Spark Core </strong>is the base for all parallel
                      data processing, and the libraries build on the core,
                      including SQL and machine learning, allow for processing a
                      diverse workload. Spark includes various libraries and
                      provides quality support for R, Scala, Java, etc.
                      <strong> Spark SQL</strong> is a simple transition for
                      users familiar with other Big Data tools, especially
                      RDBMS.
                    </p>

                    <p>
                      <strong>RDD or Resilient Distributed Dataset,</strong> is
                      considered the building block of a Spark application. The
                      data in an RDD is divided into chunks, and it is
                      immutable. RDDs can perform transformations and actions.
                    </p>

                    <br></br>

                    <img src={blogBg1} alt="" />

                    <br></br>

                    <br></br>

                    <h3 className="blog__title"><span>
                      Features of the Apache Spark Architecture</span>
                    </h3>

                    <ul>
                      <li>
                        <strong>Speed:</strong> Compared to Hadoop MapReduce,
                        Spark batch processing is 100 times faster because Spark
                        manages the data by dividing it into partitions, so data
                        can be distributed parallelly to minimize network
                        traffic.
                      </li>

                      <li>
                        <strong>Polyglot:</strong> Polyglot is used for
                        high-level APIs in R, Python, Java, and Scala, meaning
                        that coding is possible in any of these four languages.
                        It also enables shell in Scala using the installed
                        directory ./bin/spark-shell and in Python using the
                        installed directory ./bin/pyspark.
                      </li>

                      <li>
                        <strong>Real-Time Computation:</strong> The Spark has a
                        capability to process real time(stream) analysis, it
                        uses in-memory computation, so that it is processed with
                        low-latency. Spark is designed for high scalability,
                        also clusters can run from single node to thousands of
                        nodes. And it also supports many computational methods.
                      </li>

                      <li>
                        <strong>Hadoop Integration:</strong> Spark is relatively
                        new, and most of the Big Data engineers started their
                        career with Hadoop, and Spark has a compatibility with
                        Hadoop is a huge bonus. While Spark replaces the
                        MapReduce function of Hadoop, it can still run at the
                        top of the Hadoop cluster using YARN for scheduling
                        resources.
                      </li>

                      <li>
                        <strong>Machine Learning:</strong> MLib, the machine
                        learning feature of Spark is very useful for data
                        processing since it eliminates the use of other tools.
                        This gives data engineers a unified engine that’s easy
                        to operate.
                      </li>

                      <li>
                        <strong>Lazy Evaluation:</strong> The reason Spark has
                        more speed than other data processing systems is that it
                        puts off evaluation until it becomes essential. Spark
                        adds transformations to a Directed Acyclic Graph for
                        computation, and it will be executed only after the
                        driver requests action.
                      </li>
                    </ul>

                    <br></br>

                    <h3 className="blog__title"><span>Architecture</span></h3>

                    <p>
                      The below diagram explain the complete spark architecture
                    </p>

                    {/* <img src={blogBg2} alt="" /> */}

                    <p>
                      A spark cluster has a single Master and many number of
                      Slaves/Workers.
                    </p>

                    <p>
                      The driver and the executors run their individual Java
                      processes and users can run them on the same horizontal
                      spark cluster or on separate machines i.e. in a vertical
                      spark cluster or in mixed machine configuration.
                    </p>

                    <br></br>

                    <h3 className="blog__title"><span>
                      Driver in Spark Architecture</span>
                    </h3>

                    <p>
                      It is the central point and the entry point of the Spark
                      Shell.
                    </p>

                    <p>
                      The driver program runs the main () function of the
                      application and is the place where the Spark Context and
                      RDDs are created, and also it is the place where
                      transformations and actions are performed.
                    </p>

                    <p>
                      Spark Driver is responsible for the translation of spark
                      user code into actual spark jobs executed on the cluster.
                    </p>

                    <p>
                      Spark Driver performs two main tasks: Converting user
                      programs into tasks and planning the execution of tasks by
                      executors. A detailed description of its tasks is as
                      follows:
                    </p>

                    <ul>
                      <li>
                        The driver program that runs on the master node of the
                        spark cluster. It schedules the job execution and
                        negotiates with the cluster manager.
                      </li>

                      <li>
                        It translates the RDD’s into the execution graph and
                        splits the graph into multiple stages.
                      </li>

                      <li>
                        Driver stores the metadata about all the Resilient
                        Distributed Databases and their partitions.
                      </li>

                      <li>
                        Driver program converts a user application into smaller
                        execution units known as tasks. Tasks are then executed
                        by the executors i.e. the worker processes which run
                        individual tasks.
                      </li>

                      <li>
                        After the task has been completed, all the executors
                        submit their results to the Driver.
                      </li>

                      <li>
                        Driver exposes the information about the running spark
                        application.
                      </li>
                    </ul>

                    <br></br>

                    <h3 className="blog__title"><span>
                      Executor in Spark Architecture</span>
                    </h3>

                    <p>
                      An executor is a distributed agent responsible for the
                      execution of tasks.
                    </p>

                    <p>
                      Every spark application has its own executor process.
                      Executors usually run for the entire lifetime of a Spark
                      application and this phenomenon is known as “Static
                      Allocation of Executors”. However, users can also opt for
                      dynamic allocations of executors wherein they can add or
                      remove spark executors dynamically to match with the
                      overall workload.
                    </p>

                    <ul>
                      <li>
                        Executor performs all the data processing and returns
                        the results to the Driver.
                      </li>

                      <li>Reads and writes data to external sources.</li>

                      <li>
                        Executor stores the computation results data in
                        in-memory, cache or on hard disk drives.
                      </li>

                      <li>Interacts with the storage systems.</li>

                      <li>
                        Provides in-memory storage for RDDs that are collected
                        by user programs, via a utility called the Block Manager
                        that resides within each executor. As RDDs are collected
                        directly inside of executors, tasks can run parallelly
                        with the collected data.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-12">
              <div className="sidebar__content__box">
                
                <div className="single__sidebar__box">
                  <div className="title">
                    <h3>Latest posts</h3>
                  </div>

                  <div className="sidebar__blog__post">
                    <ul className="blog__post">
                      <li>
                        <div className="inner">
                          <div className="img__box">
                            <img src={b2} alt="Awesome" />
                          </div>

                          <div className="title__box">
                            {/* <div className="date">
                              <i className="icon-15"></i>26 July 2023
                            </div> */}

                            <h4>
                              <Link to="/blog-rdd">
                              RDD Transformations
                              </Link>
                            </h4>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div className="inner">
                          <div className="img__box">
                            <img src={b3} alt="Awesome" />
                          </div>

                          <div className="title__box">
                            {/* <div className="date">
                              <i className="icon-15"></i>26 July 2023
                            </div> */}

                            <h4>
                              <Link to="/blog-dataplatform">
                              Cloudera Data Platform
                              </Link>
                            </h4>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div className="inner">
                          <div className="img__box">
                            <img src={b4} alt="Awesome" />
                          </div>

                          <div className="title__box">
                            {/* <div className="date">
                              <i className="icon-15"></i>26 July 2023
                            </div> */}

                            <h4>
                              <Link to="/blog-datascience">
                              Data Science
                              </Link>
                            </h4>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="single__sidebar__box">
                  <div className="title">
                    <h3>Categories</h3>
                  </div>

                  <div className="sidebar-categories">
                    <ul className="sidebar-categories-box">
                      <li>
                        <Link to="#">
                          <i className="icon-17"></i> Spark Core
                        </Link>
                      </li>

                      <li>
                        <Link to="#">
                          <i className="icon-17"></i> Spark SQL
                        </Link>
                      </li>

                      <li>
                        <Link to="#">
                          <i className="icon-17"></i>Spark Streaming
                        </Link>
                      </li>

                      <li>
                        <Link to="#">
                          <i className="icon-17"></i>MLlib (Machine Learning Library)
                          (AI)
                        </Link>
                      </li>

                      <li>
                        <Link to="#">
                          <i className="icon-17"></i>GraphX
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterOne></FooterOne>
    </>
  );
};

export default SparkBlog;
