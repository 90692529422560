import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import HomeOne from "./Pages/HomeOne/HomeOne/HomeOne";
// import HomeTwo from "./Pages/HomeTwo/HomeTwo/HomeTwo";
// import HomeThree from "./Pages/HomeThree/HomeThree/HomeThree";
import About from "./Pages/About/About";
import ScrollTop from "./Pages/ScrollTop/ScrollTop";
import Services from "./Pages/Services/Services";
import ServicesDetails from "./Pages/Services/ServicesDetails";
import ContentMarketing from "./Pages/Services/ContentMarketing";
import DigitalMarketing from "./Pages/Services/DigitalMarketing";
import SeoMarketing from "./Pages/Services/SeoMarketing";
import UiDesign from "./Pages/Services/UiDesign";
import BusinessIntelligence from "./Pages/Services/BusinessIntelligence";
import CloudComputing from "./Pages/Services/CloudComputing";
import Projects from "./Pages/Projects/Projects";
import ProjectsDetails from "./Pages/Projects/ProjectsDetails";
import Svayambu from "./Pages/Projects/Svayambu";
import Pricing from "./Pages/Pricing/Pricing";
import Team from "./Pages/Team/Team";
import Testimonial from "./Pages/Testimonial/Testimonial";
import Error from "./Pages/Error/Error";
import Blog from "./Pages/Blog/Blog";
import BlogDetails from "./Pages/Blog/BlogDetails";
import Contact from "./Pages/Contact/Contact";
import WOW from "wowjs";
import Career from "./Pages/Projects/Career";
import Soap from "./Pages/Projects/Soap";
import RpaBlog from "./Pages/Blog/rpa";
import ReactBlog from "./Pages/Blog/ReactBlog";
import BigDataBlog from "./Pages/Blog/BigData";
import BigDataPipelineBlog from "./Pages/Blog/Bpipeline";
import DataEngineering from "./Pages/Courses/DataEngineering";
import Course from "./Pages/Courses/Course";
import MachineLeaning from "./Pages/Courses/ML";
import Python from "./Pages/Courses/Python";
import DataAnalyst from "./Pages/Courses/DataAnalyst";
import Cloud from "./Pages/Courses/Cloud";
import Payable from "./Pages/RPA/Payable";
import Report from "./Pages/RPA/reporting";
import Reconciliation from "./Pages/RPA/Reconciliation";
import Receivable from "./Pages/RPA/receivable";
import SparkBlog from "./Pages/Blog/Spark";
import RpaTraining from "./Pages/Courses/rpa";
import RddBlog from "./Pages/Blog/Rdd";
import Footer from "./Pages/Footer/footer";
import AboutTwo from "./Pages/HomeOne/AboutOne/AboutTwo";
import ServiceFooter from "./Pages/Footer/ServiceFooter";
import RpainFinance from "./Pages/Services/RpaInFinance";
import DataPlatformBlog from './Pages/Blog/DataPlatform';
import DataScienceBlog from './Pages/Blog/DataScience';
import DataIngestionBlog from './Pages/Blog/DataIngestion';
import DataWarehouse from './Pages/Blog/DataWarehouse';
import PythonBlog from './Pages/Blog/PythonBlog';
import DataPipelineBlog from "./Pages/Blog/DataPipeline";
import DataQuality from "./Pages/Blog/DataQuality";
import ITBlog from "./Pages/Blog/IT";
import HrBlog from "./Pages/Blog/Hr";
import AnalyticsTool from "./Pages/Blog/AnalyticsTool";
import ContentBlog from "./Pages/Blog/ContentWriting";
import { HelmetProvider } from "react-helmet-async";
import Whatsapp from "./Pages/Services/Whatsapp";
import Service from "./Pages/HomeOne/Service/Service";
import Performance from "./Pages/Blog/Performance";
import Seo from "./Pages/Blog/Seo";
import Security from "./Pages/Blog/Security";
import Responsive from "./Pages/Blog/Responsive";

const ScrollToTopOnRouteChange = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  useEffect(() => {
    new WOW.WOW({
      mobile: false,
    }).init();
  }, []);

  return (
    <HelmetProvider>
    <>
      <Router>
        {" "}
        <ScrollTop />
        <ScrollToTopOnRouteChange />
        <Routes>
          <Route path="/" element={<HomeOne />} />
          {/* <Route path="/home-two" element={<HomeTwo/>} /> */}
          {/* <Route path="/home-three" element={<HomeThree/>} /> */}
          <Route path="/about" element={<About />} />
          <Route path="/aboutus" element={<AboutTwo />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services-details" element={<ServicesDetails />} />
          <Route path="/RPA-Ai" element={<ContentMarketing />} />
          <Route path="/RPA-finance" element={<RpainFinance />} />
          <Route path="/Web-Development" element={<DigitalMarketing />} />
          <Route path="/Data-Engineering" element={<SeoMarketing />} />
          <Route path="/Data-Science" element={<UiDesign />} />
          <Route path="/Business-Intelligence"element={<BusinessIntelligence />}/>
          <Route path="/Cloud-Computing" element={<CloudComputing />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects-details" element={<ProjectsDetails />} />
          <Route path="/Svayambu" element={<Svayambu />} />
          <Route path="/Career-Portal" element={<Career />} />
          <Route path="/Soap-Ecommerce" element={<Soap />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/team" element={<Team />} />
          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/error" element={<Error />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-details" element={<BlogDetails />} />
          <Route path="/RPA-Blog" element={<RpaBlog />} />
          <Route path="/React-Blog" element={<ReactBlog />} />
          <Route path="/Spark-Blog" element={<SparkBlog />} />
          <Route path="/Big-Data" element={<BigDataBlog />} />
          <Route path="/Data-engineering-course" element={<DataEngineering />}/>
          <Route path="/Big-Data-Pipeline" element={<BigDataPipelineBlog  />} />
          <Route path="/rdd" element={<RddBlog />} />
          <Route path="/courses" element={<Course />} />
          <Route path="/Machine-Learning" element={<MachineLeaning />} />
          <Route path="/Python" element={<Python />} />
          <Route path="/Rpa-training" element={<RpaTraining />} />
          <Route path="/Data-Analyst" element={<DataAnalyst />} />
          <Route path="/Cloud" element={<Cloud />} />
          <Route path="/Payable" element={<Payable />} />
          <Route path="/report" element={<Report />} />
          <Route path="/Reconciliation" element={<Reconciliation />} />
          <Route path="/Receivable" element={<Receivable />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/footer" element={<Footer />} />
          <Route path="/service-footer" element={<ServiceFooter />} />
          <Route path="/blog-rdd" element={<RddBlog/>} />
          <Route path="/blog-dataplatform" element={<DataPlatformBlog/>} />
          <Route path="/blog-datascience" element={<DataScienceBlog/>} />
          <Route path="/blog-data-ingestion" element={<DataIngestionBlog/>} />
          <Route path="/blog-data-warehouse" element={<DataWarehouse/>} />
          <Route path="/blog-python" element={<PythonBlog/>} />
          <Route path="/blog-dataPipeline" element={<DataPipelineBlog/>}/>
          <Route path="/blog-Data-Quality" element={<DataQuality/>} />
          <Route path="/blog-IT" element={<ITBlog/>} />
          <Route path="/blog-Hr" element={<HrBlog/>} />
          <Route path="/blog-Analytics-Tool" element={<AnalyticsTool/>} />
          <Route path="/blog-Content-Writing" element={<ContentBlog/>} />
          <Route path="/Whatsapp-estore" element={<Whatsapp/>} />
          <Route path="/HomeService" element={<Service/>} />
          <Route path="/Performance" element={<Performance/>} />
          <Route path="/seo" element={<Seo/>} />
          <Route path="/security" element={<Security/>} />
          <Route path="/responsive" element={<Responsive/>} />
          </Routes>
      </Router>
      <ScrollTop />
    </>
    </HelmetProvider>
  );
}

export default App;
