import React from "react";
import { pageTitle } from "../PageTitle";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import BreadCrumb from "../BreadCrumb";
import { Link } from "react-router-dom";
import blogBg from "../../assets/images/news/inside-7.jpg";
import b2 from "../../assets/images/news/s-DataPlatform.jpg";
import b3 from "../../assets/images/news/s-DScience.jpg";
import b4 from "../../assets/images/news/s-dataPipeline.png";

import b5 from "../../assets/images/news/react.png";

import b6 from "../../assets/images/news/big-data 1.png";

const RddBlog = () => {
  pageTitle("Rdd Blog");

  const handleSubmitOne = (event) => {
    event.preventDefault();
  };
  const handleSubmitTwo = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <HeaderOne></HeaderOne>
      {/* <BreadCrumb></BreadCrumb> */}
      <div className="blog__details see__pad">
        <div className="auto-container">
          <div className="row">
            <div className="col-xl-8 col-lg-12 col-md-12">
              <div className="blog__details__content ">
                <div className="blog__image p_relative">
                  <img src={blogBg} alt="" />

                  <div className="post__date">
                    {/* <ul>

                    <li> <i className="icon-15"></i>26 July 2023</li>

                    <li className="two"></li>

                    <li><i className="icon-09"></i> Admin</li>

                </ul> */}
                  </div>
                </div>

                <div className="blog__inner__box">
                  <h3 className="blog__title"><span>RDD Transformations</span></h3>

                  <div className="blog__details__text">
                    <p>
                      RDD Transformations are lazy evaluation and is used to
                      transform/update from one RDD into another. When executed
                      on RDD, it results in a single or multiple new RDD.
                    </p>
                    <p>
                      Since RDD are immutable in nature, transformations always
                      create a new RDD without updating an existing one hence, a
                      chain of RDD transformations creates an RDD lineage.
                    </p>
                    <p>
                      RDD Lineage is also known as the RDD operator graph or RDD
                      dependency graph.
                    </p>
                    <p>
                      In this tutorial, you will learn lazy transformations,
                      types of transformations, a complete list of
                      transformation functions using wordcount example.
                    </p>
                    <ul>
                      <li>What is a lazy transformation</li>

                      <li>Transformation types</li>

                      <li>Narrow transformation</li>

                      <li>Wider transformation</li>

                      <li>Transformation functions</li>

                      <li>Transformation functions with word count examples</li>
                    </ul>
                    {/* <br /> */}
                    <img src=""></img>
                    <h3 className="blog__title"><span>RDD Transformation Types</span></h3>
                    <p>There are two types of transformations.</p>
                    <ul>
                      <li>
                        <h4>Narrow Transformation</h4>
                      </li>
                    </ul>
                    <p>
                      Narrow transformations are the result of map() and
                      filter() functions and these compute data that live on a
                      single partition meaning there will not be any data
                      movement between partitions to execute narrow
                      transformations. Functions such as map(), mapPartition(),
                      flatMap(), filter(), union() are some examples of narrow
                      transformation.
                    </p>
                    <ul>
                      <li>
                        <h4>Wider Transformation</h4>
                      </li>
                    </ul>
                    <p>
                      Wider transformations are the result of groupByKey() and
                      reduceByKey() functions and these compute data that live
                      on many partitions meaning there will be data movements
                      between partitions to execute wider transformations. Since
                      these shuffle the data, they are also called shuffle
                      transformations.
                    </p>
                    Functions such as groupByKey(), aggregateByKey(),
                    aggregate(), join(), repartition() are some examples of
                    wider transformations.
                    <p>
                      <strong>Note:</strong> When compared to Narrow
                      transformations, wider transformations are expensive
                      operations due to shuffling.
                    </p>
                    <p>TRANSFORMATION METHODS USAGE AND DESCRIPTION</p>
                    <ul>
                      <li>
                        <i className="icon-05"></i> <strong>cache()</strong> -
                        Caches the RDD
                      </li>

                      <li>
                        <i className="icon-05"></i> <strong>filter()</strong> -
                        Returns a new RDD after applying filter function on
                        source dataset.
                      </li>

                      <li>
                        <i className="icon-05"></i> <strong>flatMap()</strong> -
                        Returns a flattened map. For example, if you have a
                        dataset with an array, it converts each element in the
                        array to a row. In other words, it returns 0 or more
                        items in output for each element in the dataset.
                      </li>

                      <li>
                        <i className="icon-05"></i> <strong>map()</strong> -
                        Applies transformation function on dataset and returns
                        the same number of elements in the distributed dataset.
                      </li>

                      <li>
                        <i className="icon-05"></i>{" "}
                        <strong>mapPartitions()</strong> - Similar to map, but
                        executes the transformation function on each partition,
                        giving better performance than the map function.
                      </li>

                      <li>
                        <i className="icon-05"></i>{" "}
                        <strong>mapPartitionsWithIndex()</strong> - Similar to
                        mapPartitions, but also provides a function with an
                        integer value representing the index of the partition.
                      </li>

                      <li>
                        <i className="icon-05"></i>{" "}
                        <strong>randomSplit()</strong> - Splits the RDD by the
                        weights specified in the argument. For example,{" "}
                        <code>rdd.randomSplit(0.7, 0.3)</code>
                      </li>

                      <li>
                        <i className="icon-05"></i> <strong>union()</strong> -
                        Combines elements from the source dataset and the
                        argument and returns a combined dataset. This is similar
                        to the union function in Math set operations.
                      </li>

                      <li>
                        <i className="icon-05"></i> <strong>sample()</strong> -
                        Returns the sample dataset.
                      </li>

                      <li>
                        <i className="icon-05"></i>{" "}
                        <strong>intersection()</strong> - Returns the dataset
                        which contains elements in both the source dataset and
                        an argument.
                      </li>

                      <li>
                        <i className="icon-05"></i> <strong>distinct()</strong>{" "}
                        - Returns the dataset by eliminating all duplicate
                        elements.
                      </li>

                      <li>
                        <i className="icon-05"></i>{" "}
                        <strong>repartition()</strong> - Returns a dataset with
                        the number of partitions specified in the argument. This
                        operation reshuffles the RDD randomly. It could either
                        return a lesser or more partitioned RDD based on the
                        input supplied.
                      </li>

                      <li>
                        <i className="icon-05"></i> <strong>coalesce()</strong>{" "}
                        - Similar to repartition but operates better when we
                        want to decrease the partitions. Better performance is
                        achieved by reshuffling data from fewer nodes compared
                        with all nodes by repartition.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-12">
              <div className="sidebar__content__box">


                <div className="single__sidebar__box">
                  <div className="title">
                    <h3>Latest posts</h3>
                  </div>

                  <div className="sidebar__blog__post">
                    <ul className="blog__post">
                      <li>
                        <div className="inner">
                          <div className="img__box">
                            <img src={b2} alt="Awesome" />
                          </div>

                          <div className="title__box">
                            {/* <div className="date">
                              <i className="icon-15"></i>26 July 2023
                            </div> */}

                            <h4>
                              <Link to="/blog-dataplatform">
                              Cloudera Data Platform
                              </Link>
                            </h4>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div className="inner">
                          <div className="img__box">
                            <img src={b3} alt="Awesome" />
                          </div>

                          <div className="title__box">
                            {/* <div className="date">
                              <i className="icon-15"></i>26 July 2023
                            </div> */}

                            <h4>
                              <Link to="/blog-datascience">
                              Data Science
                              </Link>
                            </h4>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div className="inner">
                          <div className="img__box">
                            <img src={b4} alt="Awesome" />
                          </div>

                          <div className="title__box">
                            {/* <div className="date">
                              <i className="icon-15"></i>26 July 2023
                            </div> */}

                            <h4>
                              <Link to="/blog-dataPipeline">
                              Data pipeline
                              </Link>
                            </h4>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="single__sidebar__box">
                  <div className="title">
                    <h3>Categories</h3>
                  </div>

                  <div className="sidebar-categories">
                    <ul className="sidebar-categories-box">
                      <li>
                        <Link to="#">
                          <i className="icon-17"></i> Map and FlatMap
                        </Link>
                      </li>

                      <li>
                        <Link to="#">
                          <i className="icon-17"></i> Filter
                        </Link>
                      </li>

                      <li>
                        <Link to="#">
                          <i className="icon-17"></i>GroupByKey and ReduceByKey
                        </Link>
                      </li>

                      <li>
                        <Link to="#">
                          <i className="icon-17"></i>Join and Cogroup
                          (AI)
                        </Link>
                      </li>

                      <li>
                        <Link to="#">
                          <i className="icon-17"></i>Union and Intersection
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterOne></FooterOne>
    </>
  );
};

export default RddBlog;
