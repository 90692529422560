import React from "react";
import { pageTitle } from "../PageTitle";
import BreadCrumb from "../BreadCrumb";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import { Link } from "react-router-dom";
import portfolio1 from "../../assets/images/resource/portfolio-3.png";
import portfolio2 from "../../assets/images/resource/portfolio-1.png";
import portfolio3 from "../../assets/images/resource/portfolio-4.png";
import project from "../../assets/images/resource/project-details-3.jpg";

const Career = () => {
  pageTitle("Career Project");
  const projrctData = [
    {
      id: 1,
      image: portfolio1,
      link: '/svayambu',
    },
    {
      id: 2,
      image: portfolio2,
      link: '/projects-details',
    },
    {
      id: 3,
      image: portfolio3,
      link: '/soap-ecommerce',
    },
  ];
  return (
    <>
      <HeaderOne></HeaderOne>
      {/* <BreadCrumb></BreadCrumb> */}
      <section className="project__details p_relative">
        <div className="auto-container">
          <div className="image">
            <figure>
              <img src={project} alt="" />
            </figure>
          </div>
          {/* <div className="pro__info">
            <ul>
              <li>
                <div className="name">
                  <p>Client :</p>
                </div>
                <div className="name__two">
                  <h4>Jone welliam</h4>
                </div>
              </li>
              <li>
                <div className="name">
                  <p>Category :</p>
                </div>
                <div className="name__two">
                  <h4>Marketing</h4>
                </div>
              </li>
              <li>
                <div className="name">
                  <p>Date :</p>
                </div>
                <div className="name__two">
                  <h4>15 July, 2023</h4>
                </div>
              </li>
            </ul>
          </div> */}
          <div className="data">
            <h4><span>
              Empowering Career Growth: A Comprehensive Development Platform</span>
            </h4>
          </div>
          <p>
            IAASn today's dynamic job market, navigating career development can be
            challenging. This project addresses these challenges through a
            comprehensive platform that incorporates a suite of specialized
            tools, each designed to support users at different stages of their
            career journey.
          </p>
          <div className="project__button">
            <div className="button__one">
              <Link to="https://reactai.siw.sg/" className="theme-btn theme-btn-one" target='_blank'>Preview</Link>
            </div>
           
          </div>
          <div className="data">
            <h4><span>AI Career Guidance & Tools Hub</span></h4>
          </div>
          {/* <p>
            The AI.SIW platform serves as an innovative hub for professionals
            seeking to enhance their career trajectories through artificial
            intelligence. It offers a suite of tools designed to address various
            aspects of career development, ensuring users have access to
            comprehensive resources tailored to their needs.
          </p> */}
          <div className="data__list">
            <ul>
              <li>
                {" "}
                <i className="icon-05"></i>The Situation Tool empowers users to assess their current circumstances, providing a foundation for informed decision-making. Complementing this, the Discovery Tool helps users uncover potential career paths and opportunities that align with their skills and aspirations. For those exploring specific job roles, the Role Analyzer offers detailed insights, allowing users to make educated choices about their career trajectories.
              </li>
              <li>
                {" "}
                <i className="icon-05"></i>Transitioning careers can be daunting, but our Career Transition Tool provides the necessary support and guidance to facilitate this process smoothly. To aid in the job application process, the Interview Tool prepares users for interviews, offering tips and best practices to enhance their performance. Meanwhile, the Resume Tool assists users in crafting compelling resumes that highlight their qualifications effectively.
              </li>
              <li>
                {" "}
                <i className="icon-05"></i>Tracking progress is crucial for career development, which is why the Career Tracking Tool has been integrated into the platform. This tool enables users to monitor their journey, set milestones, and celebrate achievements over time.
              </li>
              <li>
                {" "}
                <i className="icon-05"></i>Designed with a user-friendly interface reminiscent of ChatGPT, the platform ensures that users can easily navigate its features. A support chat function further enhances the user experience, providing real-time assistance for any inquiries or challenges faced during their career exploration.
              </li>
              <li>
                {" "}
                <i className="icon-05"></i>Overall, this platform is a holistic solution for individuals seeking to advance their careers. By combining essential tools into one cohesive system, it empowers users to take charge of their professional development and make informed decisions that lead to successful outcomes.
              </li>
              <li> </li>
              {/* <li>
                {" "}
                <i className="icon-05"></i>Professional Development Planning:
                Lastly, the platform emphasizes continuous professional growth.
                It offers personalized development plans based on users' career
                goals, skill gaps, and industry trends. By focusing on lifelong
                learning, users can remain competitive and adaptable in an
                ever-changing job landscape.
              </li> */}
            </ul>
          </div>

          {/* <div className="project__button">
            <div className="button__one">
              <Link to="https://reactai.siw.sg/" className="theme-btn theme-btn-one">Preview</Link>
            </div>
           
          </div> */}
        </div>
      </section>
      {/* <div className="project__page p_relative see__pad">
        <div className="auto-container">
          <div className="row">
            {projrctData.map((item, id) => (
              <div className="col-lg-4 col-md-6 col-sm-12" key={id}>
                <div className="portfolio__block p_relative">
                  <div className="portfolio__image">
                    <figure>
                      <img src={item.image} alt="" />
                    </figure>
                  </div>
                  <div className="lower__content p_absolute">
                    <div className="protfolio__text">
                      <div className="text__block">
                        <h4>For more projects</h4>
                        <p>Design</p>
                      </div>
                      <div className="text__block_two">
                       
                      </div>
                    </div>
                    <div className="protfolio__button">
                    <Link to={item.link} className="theme-btn theme-btn-one">
                          Read More<i className="icon-02"></i>
                        </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}
      <FooterOne></FooterOne>
    </>
  );
};

export default Career;
