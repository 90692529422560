import React from 'react';
import { pageTitle } from '../PageTitle';
import HeaderOne from '../Header/HeaderOne';
import FooterOne from '../Footer/FooterOne';
import BreadCrumb from '../BreadCrumb';
import { Link } from 'react-router-dom';
import blogBg from "../../assets/images/news/wp-ai.jpg";
import inside from "../../assets/images/news/inside-2.jpg";
import b3 from "../../assets/images/news/s-python.jpg";
import b4 from "../../assets/images/news/s-quality.png";
import b5 from "../../assets/images/news/s-analytics.png";
import b6 from "../../assets/images/news/sidebar-3.jpg";
import ServiceFooter from '../Footer/ServiceFooter';


const DataWarehouse = () => {
    pageTitle('DataWarehouse');

    const handleSubmitOne = (event) => {
        event.preventDefault();
    };
    const handleSubmitTwo = (event) => {
        event.preventDefault();
    };

    return (

        <>
            <HeaderOne></HeaderOne>
            {/* <BreadCrumb></BreadCrumb> */}
            <div className="blog__details see__pad">
                <div className="auto-container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-12 col-md-12">
                            <div className="blog__details__content ">
                                <div className="blog__image p_relative">
                                    <img src={inside} alt="" />

                                </div>
                                <div className="blog__inner__box">
                                    <h3 className="blog__title"><span>Data Warehouse</span></h3>
                                    <div className="blog__details__text">
                                        <p className="blog__intro">A data warehouse is an environment or centralized repository for storing large amounts of data accumulated from a wide range of sources. It is used to guide management decisions by providing key insights.</p>

                                        <h3 className="blog__title"><span>Top Data Warehouse Providers</span></h3>
                                        {/* <br></br> */}
                                        <ul className="blog__list">
                                            <li><strong>Amazon Redshift:</strong> A fast and scalable data warehouse service offered by AWS, optimized for big data analytics.</li>
                                            <li><strong>Google BigQuery:</strong> A serverless, highly scalable data warehouse solution from Google, known for its high-speed analysis on massive datasets.</li>
                                            <li><strong>IBM Db2 Warehouse:</strong> A fully managed, elastic cloud data warehouse built for high performance and advanced analytics.</li>
                                            <li><strong>Azure Synapse Analytics:</strong> Microsoft's end-to-end data warehouse and big data analytics service, integrated with advanced analytics capabilities.</li>
                                            <li><strong>Oracle Autonomous Data Warehouse:</strong> A self-driving, fully managed data warehouse that automatically scales, secures, and tunes itself.</li>
                                            <li><strong>SAP Data Warehouse Cloud:</strong> SAP's cloud data warehouse that combines data management and advanced analytics.</li>
                                            <li><strong>Snowflake:</strong> A cloud-native data platform that supports multi-cloud and cross-cloud data integration and sharing.</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-12">
                            <div className="sidebar__content__box">

                                <div className="single__sidebar__box">
                                    <div className="title">
                                        <h3>Latest posts</h3>
                                    </div>
                                    <div className="sidebar__blog__post">
                                        <ul className="blog__post">
                                            <li>
                                                <div className="inner">
                                                    <div className="img__box">
                                                        <img src={b3} alt="Awesome" />
                                                    </div>
                                                    <div className="title__box">
                                                        {/* <div className="date"><i className="icon-15"></i>26 July 2023</div> */}
                                                        <h4><Link to="/blog-python">Just Enough Python</Link></h4>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="inner">
                                                    <div className="img__box">
                                                        <img src={b4} alt="Awesome" />
                                                    </div>
                                                    <div className="title__box">
                                                        {/* <div className="date"><i className="icon-15"></i>26 July 2023</div> */}
                                                        <h4><Link to="/blog-Data-Quality"  >Data Quality Management</Link></h4>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="inner">
                                                    <div className="img__box">
                                                        <img src={b5} alt="Awesome" />
                                                    </div>
                                                    <div className="title__box">
                                                        {/* <div className="date"><i className="icon-15"></i>26 July 2023</div> */}
                                                        <h4>
                              <Link to="/blog-Analytics-Tool">
                                Integration with Analytics Tools
                              </Link>
                            </h4>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="single__sidebar__box">
                                    <div className="title">
                                        <h3>Categories</h3>
                                    </div>
                                    <div className="sidebar-categories">
                                        <ul className="sidebar-categories-box">
                                            <li><Link to="#"><i className="icon-17"></i> Data Integration and ETL</Link></li>
                                            <li><Link to="#"><i className="icon-17"></i> Data Modeling and Schema Design</Link></li>
                                            <li><Link to="#"><i className="icon-17"></i>Data Storage and Management</Link></li>
                                            <li><Link to="#"><i className="icon-17"></i>Data Querying and Analytics</Link></li>
                                            <li><Link to="#"><i className="icon-17"></i>Data Governance and Security</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ServiceFooter/>
        </>
    );
};

export default DataWarehouse;
