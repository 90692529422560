import React from 'react';
import choose1 from "../../../assets/images/resource/choose-01.png";
import choose2 from "../../../assets/images/resource/choose-01.png";
import choose3 from "../../../assets/images/resource/choose-01.png";
import choose4 from "../../../assets/images/resource/choose-01.png";

const Choose = () => {
    return (
        <section className="choose__us p_relative">
            <div className="choose__us__data">
                <div className="auto-container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="choose__block">
                                <figure>
                                    <img src={choose1} alt="" />
                                </figure>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="choose__block__right">
                                <div className="">
                                    <div className="sub__title">
                                        <h4>Why choose us</h4>
                                    </div>
                                    <div className="title">
                                        <h2 style={{color:"#fff"}}>We Provide Your Best Work </h2>
                                    </div>
                                </div>
                                <div className="team__data">
                                    {/* <div className="team__data__left">
                                        <figure>
                                        <img src={choose2} alt="" />
                                    </figure>

                                    </div> */}
                                    <div className="team__data__right">
                                        <h3>Tailored Solutions for Your Evolving Needs</h3>
                                        <p>SIW delivers reliable and cost-effective applications designed to evolve with your business. We ensure seamless integration into your existing processes, providing solutions that align with your goals and adapt to your changing needs.</p>
                                    </div>
                                </div>
                                <div className="team__data">
                                    {/* <div className="team__data__left">
                                        <img src={choose3} alt="" />
                                    </div> */}
                                    <div className="team__data__right">
                                        <h3>End-to-End Software Development Expertise</h3>
                                        <p>We manage the complete software development cycle, from initial analysis to deployment and maintenance. Our approach guarantees smooth workflows and added value at every stage, giving you confidence and a hassle-free experience.</p>
                                    </div>
                                </div>
                                <div className="team__data three">
                                    {/* <div className="team__data__left">
                                        <img src={choose4} alt="" />
                                    </div> */}
                                    <div className="team__data__right">
                                        <h3>Advanced BI Tools for Better Profitability</h3>
                                        <p> SIW's advanced Business Intelligence tools enhance supply chain visibility and profitability. By integrating seamlessly with leading ERPs like SAP and Oracle, we help you boost efficiency and make data-driven decisions for greater success.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Choose;