import React from 'react';
import { pageTitle } from '../PageTitle';
import HeaderOne from '../Header/HeaderOne';
import FooterOne from '../Footer/FooterOne';
import { Link } from 'react-router-dom';
import news1 from "../../assets/images/news/news-1.png";
import news2 from "../../assets/images/news/news-2.png";
import news3 from "../../assets/images/news/news-3.png";
import news4 from "../../assets/images/news/big-pipeline.png";
import news5 from "../../assets/images/news/big-data.png";
import news7 from "../../assets/images/resource/Spark.png";
import news8 from "../../assets/images/news/news-8.png";
import news9 from "../../assets/images/news/news-9.png";
import news10 from "../../assets/images/news/news-10.png";
import news11 from "../../assets/images/news/news-11.png";
import news12 from "../../assets/images/news/news-12.png";
import news13 from "../../assets/images/news/news-13.png";
import news14 from "../../assets/images/news/news-14.png";
import newsa from "../../assets/images/news/news-a.png";
import newsb from "../../assets/images/news/news-b.png";
import newsc from "../../assets/images/news/news-c.png";
import newsd from "../../assets/images/news/news-d.png";
import newse from "../../assets/images/news/news-e.png";
import news15 from "../../assets/images/news/res.png";
import news16 from "../../assets/images/news/sec.png";
import news17 from "../../assets/images/news/seo-1.png";
import ServiceFooter from '../Footer/ServiceFooter';

const Blog = () => {
  pageTitle('Blog');
  const blogData = [
    {
      id: 1,
      title: 'WhatsApp and OpenAI interfaces',
      image: news1,
      href: '/blog-details',
      data_aos_delay: '00ms',
    },
    {
      id: 2,
      title: 'RPA Mastery: Your Essential Guide',
      image: news2,
      href: '/rpa-blog',
      data_aos_delay: '100ms',
    },
    {
      id: 3,
      title: 'React js: Build Dynamic Web Applications Easily',
      date: '26 July 2023',
      author: 'Admin',
      image: news3,
      href: '/react-blog',
      data_aos_delay: '200ms',
    },
    {
      id: 4,
      title: 'Building Scalable Big Data Pipelines: From Ingestion to Analytics',
      date: '26 July 2023',
      author: 'Admin',
      image: news4,
      href: '/Big-Data-Pipeline',
      data_aos_delay: '200ms',
    },
    {
      id: 5,
      title: 'From Data to Decisions: Navigating the Big Data Landscape',
      date: '26 July 2023',
      author: 'Admin',
      image: news5,
      href: '/big-data',
      data_aos_delay: '200ms',
    },
    {
      id: 6,
      title: 'Unlocking Big Data: A Beginners Guide to Apache Spark',
      date: '26 July 2023',
      author: 'Admin',
      image: news7,
      href: '/spark-blog',
      data_aos_delay: '200ms',
    },
    {
      id: 7,
      title: 'RDD Transformations',
      date: '26 July 2023',
      author: 'Admin',
      image: news8,
      href: '/blog-rdd',
      data_aos_delay: '200ms',
    },
    {
      id: 8,
      title: 'Cloudera Data Platform',
      date: '26 July 2023',
      author: 'Admin',
      image: news14,
      href: '/blog-dataplatform',
      data_aos_delay: '200ms',
    },
    {
      id: 9,
      title: 'Data Science',
      date: '26 July 2023',
      author: 'Admin',
      image: news12,
      href: '/blog-datascience',
      data_aos_delay: '200ms',
    },
    {
      id: 10,
      title: 'Data pipeline',
      date: '26 July 2023',
      author: 'Admin',
      image: news13,
      href: '/blog-dataPipeline',
      data_aos_delay: '200ms',
    },
    {
      id: 11,
      title: 'Data Ingestion',
      date: '26 July 2023',
      author: 'Admin',
      image: news9,
      href: '/blog-data-ingestion',
      data_aos_delay: '200ms',
    },
    {
      id: 12,
      title: 'Data warehouse',
      date: '26 July 2023',
      author: 'Admin',
      image: news10,
      href: '/blog-data-warehouse',
      data_aos_delay: '200ms',
    },
    {
      id: 13,
      title: 'Just Enough Python',
      date: '26 July 2023',
      author: 'Admin',
      image: news11,
      href: '/blog-python',
      data_aos_delay: '200ms',
    },
    {
      id: 14,
      title: 'Data Quality Management',
      date: '26 July 2023',
      author: 'Admin',
      image: newsa,
      href: '/blog-Data-Quality',
      data_aos_delay: '200ms',
    },
    {
      id: 15,
      title: 'Integration with Analytics Tools',
      date: '26 July 2023',
      author: 'Admin',
      image: newsb,
      href: '/blog-Analytics-Tool',
      data_aos_delay: '200ms',
    },
    {
      id: 16,
      title: 'Human Resources',
      date: '26 July 2023',
      author: 'Admin',
      image: newsc,
      href: '/blog-HR',
      data_aos_delay: '200ms',
    },
    {
      id: 17,
      title: 'Content Writing Guide',
      date: '26 July 2023',
      author: 'Admin',
      image: newsd,
      href: '/blog-Content-Writing',
      data_aos_delay: '200ms',
    },
    {
      id: 18,
      title: 'IT Support ',
      date: '26 July 2023',
      author: 'Admin',
      image: newse,
      href: '/blog-IT',
      data_aos_delay: '200ms',
    },
    {
      id: 19,
      title: 'Responsive Design',
      date: '26 July 2023',
      author: 'Admin',
      image: news15,
      href: '/responsive',
      data_aos_delay: '200ms',
    },
    {
      id: 20,
      title: 'Security',
      date: '26 July 2023',
      author: 'Admin',
      image: news16,
      href: '/responsive',
      data_aos_delay: '200ms',
    },
    {
      id: 21,
      title: 'SEO Optimization',
      date: '26 July 2023',
      author: 'Admin',
      image: news17,
      href: '/responsive',
      data_aos_delay: '200ms',
    },
  ];

  return (
    <>
      <HeaderOne />
      <div className="blog__page p_relative see__pad">
        <div className="auto-container">
          <div className="row">
            {blogData.map((item, id) => (
              <div className="col-lg-4 col-md-6 col-sm-12 news__block" key={id}>
                <div className="news___block wow slideInUp animated" data-wow-delay={item.data_aos_delay} data-wow-duration="1500ms">
                  <div className="inner-box p_relative">
                    <div className="image-box p_relative d_block">
                      <figure className="image p_relative d_block">
                        <Link to={item.href} target="_blank" rel="noopener noreferrer">
                          <img src={item.image} alt="" />
                        </Link>
                      </figure>
                    </div>
                    <div className="lower___content p_relative">
                      <h4>
                        <Link to={item.href} target="_blank" rel="noopener noreferrer">
                          {item.title}
                        </Link>
                      </h4>
                      <div className="btn__box__two">
                        <Link to={item.href} target="_blank" rel="noopener noreferrer" className="theme__btn__two">
                          <i className="icon-02"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ServiceFooter/>
    </>
  );
};

export default Blog;
