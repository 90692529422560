import React, { useState } from "react";
import { pageTitle } from "../PageTitle";
import BreadCrumb from "../BreadCrumb";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import { Link, useLocation } from "react-router-dom";
import two from "../../assets/images/resource/choose-01.png";
import service from "../../assets/images/resource/cloud.jpg";
import basebg from "../../assets/images/resource/base.png";
import g1 from "../../assets/images/resource/cc.png";
import { Helmet } from "react-helmet-async";

const CloudComputing = () => {
  pageTitle("Cloud computing");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });

  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Your logic to handle form submission goes here
    console.log("Form submitted:", formData);

    // Clear the form fields after submission if needed
    setFormData({
      name: "",
      email: "",
      subject: "",
      phone: "",
      message: "",
    });
  };

  const servicesData = [
    { id: 1, title: "RPA & AI", slug: "/RPA-AI" },
    { id: 2, title: "Whatsapp Services", slug: "/Whatsapp-estore" },
    { id: 3, title: "Web Development", slug: "/Web-Development" },
    { id: 4, title: "Data Engineering", slug: "/Data-Engineering" },
    { id: 5, title: "Data Science", slug: "/Data-Science" },

    { id: 6, title: "Cloud-Computing", slug: "/Cloud-Computing" },
  ];

  const renderServices = () => {
    return servicesData.map((service) => (
      <li
        key={service.id}
        className={service.slug === "/digital-marketing" ? "active" : ""}
      >
        <Link to={service.slug}>{service.title}</Link>
      </li>
    ));
  };

  return (
    <>
      <Helmet>
        <title>Cloud Computing Solutions | Future of Technology</title>
        <meta
          name="description"
          content="Discover the advantages of Cloud Computing, including cost savings, scalability, and global reach. Learn about IaaS, PaaS, and SaaS models."
        />
        <meta
          name="keywords"
          content="Cloud Computing, IaaS, PaaS, SaaS, Public Cloud, Hybrid Cloud, Private Cloud"
        />
        <meta
          property="og:title"
          content="Cloud Computing Solutions | Future of Technology"
        />
        <meta
          property="og:description"
          content="Explore the world of Cloud Computing and its benefits, including scalability, agility, and cost-efficiency. Understand IaaS, PaaS, and SaaS."
        />
        <meta property="og:image" content={service} />
        <meta property="og:url" content="https://siw.sg/cloud-computing" />
        <meta property="og:type" content="website" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://siw.sg/cloud-computing" />
      </Helmet>

      <HeaderOne></HeaderOne>
      {/* <BreadCrumb></BreadCrumb> */}
      <section className="page__title p_relative">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${g1})` }}
        ></div>
        <div className="auto-container">
          <div className="content__box p_relative">
            {/* <ul className="bread__crumb">
                    <li><Link to="/">Home</Link></li>
                    {pathnames.map((name, index) => (
                    <li key={index}>
                        <span> -</span>
                        <Link to={`/${pathnames.slice(0, index + 1).join('/')}`}>{name}</Link>
                    </li>
                    ))}
                </ul> */}
            {/* <h1 className="title">{pathnames.length > 0 ? pathnames[pathnames.length - 1] : 'Home'}</h1> */}
          </div>
        </div>
      </section>
      <div className="service__details p_relative ">
        <div className="auto-container">
          <div className="row">
            <div className="col-xl-8 col-lg-12 col-md-12">
              <div className="service__right">
                <figure>
                  <img src={service} alt="" />
                </figure>
                <h2>
                  The Future of Technology: Exploring{" "}
                  <span>Cloud Computing Solutions.</span>{" "}
                </h2>
                <h3>
                  <span>6 Advantages Of Cloud Computing</span>{" "}
                </h3>
                <ul>
                  <li>
                    <span>Trade Capital Expense For Variable Expense :</span>
                    Instead of having to invest heavily in data centers and
                    servers before you know how you're going to use them, you
                    can pay only when you consume computing resources, and pay
                    only for how much you consume.
                  </li>
                  <li>
                    <span>Benefit from massive economies of scale :</span> You
                    will never have the same purchasing power as Amazon. They
                    literally build their own servers!
                  </li>
                  <li>
                    <span>Stop guessing about capacity :</span> You will
                    probably either buy too much or too little. If you buy too
                    much, you've wasted money and if you buy too little you will
                    have down time. Cloud can scale with your business needs,
                    with no long term contracts
                  </li>
                  <li>
                    <span>Increase speed and agility :</span>The A Cloud Guru
                    platform was built in 3 weeks, using a new type of design
                    called serverless architecture. It scales infinitely with
                    demand. 15 years ago we would have had to buy servers and
                    rent data center space and we probably never would have got
                    off the ground
                  </li>
                  <li>
                    <span>
                      Stop spending money running and maintaining data centers :
                    </span>
                    Focus on what you are good at, not at managing
                    infrastructure. Let someone else manage that for you.
                  </li>
                  <li>
                    <span>Go global in minutes :</span>Easily deploy your
                    application in multiple regions around the world with just a
                    few clicks. This means you can provide lower latency and a
                    better experience for your customers at minimal cost
                  </li>
                </ul>

                <h3>
                  <span>Three Types of Cloud Computing</span>
                </h3>
                <p>
                  <span>Infrastructure As A Service (IAAS)</span>
                  <br></br>
                </p>
                <ul>
                  <li>
                    {" "}
                    You manage the server which can be physical or virtual, as
                    well as the operating system. Usually the data centre
                    provider will have no access to your server
                  </li>
                </ul>
                <p>
                  <span>Platform As A Service (PAAS)</span>
                  <br></br>
                </p>
                <ul>
                  <li>
                    {" "}
                    Someone else manages the underlying hardware and operating
                    systems. You just focus on your applications. Someone else
                    worries about security patching, updates, maintenance etc.
                  </li>
                </ul>
                <p>
                  <span>Software As A Service (SAAS)</span>
                </p>
                <ul>
                  <li>
                    Think of Gmail. All you manage is your inbox (or the
                    software provided to you by Google). Google take care of the
                    data centers, servers, networks, storage, maintenance,
                    patching etc. All you worry about is the software itself and
                    how you want to use it.
                  </li>
                </ul>
                <h3>
                  <span>Three Types of Cloud Computing Deployments</span>
                </h3>
                <p>
                  <span>Public Cloud - AWS, Azure, GCP</span>
                </p>
                <ul>
                  <li>
                    1. The Public cloud is a cloud deployment model where
                    computing resources are owned and operated by a provider and
                    shared across multiple tenants via the Internet.
                  </li>
                  <li>
                    2. Public cloud makes computing resources available to
                    anyone for purchase. Multiple users typically share the use
                    of a public cloud. In contrast, private cloud involves
                    cloud-based services that are hosted within an
                    organization's own private servers.
                  </li>
                </ul>
                <p>
                  <span>Hybrid-Mixture of public and private</span>
                </p>
                <ul>
                  <li>
                    1. A hybrid cloud is a cloud computing environment that uses
                    a mix of on-premises, private cloud and third-party, public
                    cloud services with orchestration between these platforms.
                    This typically involves a connection from an on-premises
                    data center to a public cloud. The connection also can
                    involve other private assets, including edge devices or
                    other clouds.
                  </li>
                </ul>
                <p>
                  <span>Hybrid-Mixture of public and private</span>
                </p>
                <ul>
                  <li>
                    1.Private cloud provides a high level of security and
                    privacy to data through firewalls and internal hosting. It
                    also ensures that operational and sensitive data are not
                    accessible to third-party providers.
                  </li>
                  <li>
                    2. Private clouds have more control over their resources and
                    hardware than public clouds because it is only accessed by
                    selected users.
                  </li>
                  <li>
                    3. Security & privacy are one of the big advantages of cloud
                    computing. Private cloud improved the security level as
                    compared to the public cloud.
                  </li>
                  <li>
                    4. Private cloud offers better performance with improved
                    speed and space capacity.
                  </li>
                </ul>

                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="#">
                        <i className="icon-04"></i> Data Integration and
                        Warehousing
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="https://ai.siw.sg/">
                        <i className="icon-04"></i> Advanced Analytics and
                        Reporting
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="#">
                        <i className="icon-04"></i>Predictive Analytics and
                        Forecasting{" "}
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="#">
                        <i className="icon-04"></i> Self-Service Analytics
                      </Link>
                    </li>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-12">
              <div className="service__details__left">
                <div className="sidebar__widget">
                  <div className="all__service">
                    <h4>All Services</h4>
                  </div>
                  <ul className="service__details__1__cat">
                    {renderServices()}
                  </ul>
                </div>
                <div
                  className=" sidebar__widget_network"
                  style={{ backgroundImage: `url(${basebg})` }}
                >
                  <h3>Contact Us For Any Require</h3>
                  <div className="services__icon">
                    <i className="icon-19"></i>
                  </div>
                  <p>Need help? Call us:</p>
                  <h4>+65 90059743</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="service__contact see__pad p_relative">
        <div className="auto-container">
          <div className="service__contact__block">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="service__form__data">
                  <div className="form__title">
                    <div className="title__data">
                      <div className="sub__title">
                        <h4>Contact Us</h4>
                      </div>
                      <div className="title">
                        <h2>Send message</h2>
                      </div>
                    </div>
                  </div>
                  <div className="form-inner">
                    <form
                      onSubmit={handleSubmit}
                      method="post"
                      action="contact.php"
                      className="default-form"
                    >
                      <div className="row clearfix">
                        <div className="col-xl-6 form-group">
                          <input
                            type="name"
                            name="name"
                            placeholder="Your Name *"
                            required=""
                          />
                        </div>
                        <div className="col-xl-6 col-lg-12 form-group">
                          <input
                            type="email"
                            name="email"
                            placeholder="Your Email *"
                            required=""
                          />
                        </div>
                        <div className="col-xl-6 col-lg-12 form-group">
                          <input
                            type="text"
                            name="subject"
                            placeholder="Your Subject "
                            required=""
                          />
                        </div>
                        <div className="col-xl-6 col-lg-12 form-group">
                          <input
                            type="text"
                            name="phone"
                            placeholder="Your Phone "
                            required=""
                          />
                        </div>
                        <div className="ccol-xl-6 col-lg-12 form-group">
                          <textarea
                            name="message"
                            placeholder="Message"
                          ></textarea>
                        </div>
                        <div className="col-xl-12 form-group message-btn btn-box">
                          <button
                            className="theme-btn theme-btn-one"
                            type="submit"
                            name="submit-form"
                          >
                            Send your Message
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 p_relative">
                <div className="service__contact__img">
                  <figure>
                    <img src={two} alt=" /" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <FooterOne></FooterOne>
    </>
  );
};

export default CloudComputing;
