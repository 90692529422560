import React from "react";
import { pageTitle } from "../PageTitle";
import BreadCrumb from "../BreadCrumb";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import { Link } from "react-router-dom";
import portfolio1 from "../../assets/images/resource/portfolio-1.png";
import portfolio2 from "../../assets/images/resource/portfolio-2.png";
import portfolio3 from "../../assets/images/resource/portfolio-3.png";
import project from "../../assets/images/resource/project-details-4.jpg";

const Soap = () => {
  pageTitle("Soap Project");
  const projrctData = [
    {
      id: 1,
      image: portfolio1,
      link: '/projects-details',
    },
    {
      id: 2,
      image: portfolio2,
      link: '/Career-Portal',
    },
    {
      id: 3,
      image: portfolio3,
      link: '/svayambu',
    },
  ];
  return (
    <>
      <HeaderOne></HeaderOne>
      {/* <BreadCrumb></BreadCrumb> */}
      <section className="project__details p_relative">
        <div className="auto-container">
          <div className="image">
            <figure>
              <img src={project} alt="" />
            </figure>
          </div>
          {/* <div className="pro__info">
            <ul>
              <li>
                <div className="name">
                  <p>Client :</p>
                </div>
                <div className="name__two">
                  <h4>Jone welliam</h4>
                </div>
              </li>
              <li>
                <div className="name">
                  <p>Category :</p>
                </div>
                <div className="name__two">
                  <h4>Marketing</h4>
                </div>
              </li>
              <li>
                <div className="name">
                  <p>Date :</p>
                </div>
                <div className="name__two">
                  <h4>15 July, 2023</h4>
                </div>
              </li>
            </ul>
          </div> */}
          <div className="data">
            <h4><span>Creating a Soap Business eCommerce Website with Billing Automation</span></h4>
          </div>
          {/* <p>
            At AI.SIW, we leverage the power of AI to help you achieve your
            career goals with tailored guidance and advanced tools. Our platform
            offers comprehensive services, including:
          </p> */}
          <div className="data">
            {/* <h4>AI Career Guidance & Tools Hub</h4> */}
          </div>
          <p>
          As a team, we developed a fully functional eCommerce website for a handmade soap business using WordPress. The project aimed to create a user-friendly platform where customers could browse various soap products, select different scents, and easily place orders. 
          </p>
          <div className="project__button">
            <div className="button__one">
              <Link to="http://52.221.211.122/" className="theme-btn theme-btn-one" target='_blank'>Preview</Link>
            </div>
            
          </div>
          <div className="data__list">
            <ul>
              <li>
                {" "}
                <i className="icon-05"></i>Utilizing WooCommerce, we seamlessly integrated a billing system that supported multiple payment gateways like PayPal and Stripe to provide flexibility to customers. Additionally, we set up an automated invoicing system, ensuring that once an order was placed, a detailed bill was immediately sent to the customer's email. This feature streamlined the process and enhanced user experience by delivering instant order confirmation and purchase breakdowns. 
              </li>
              <li>
                {" "}
                <i className="icon-05"></i>Throughout the design, we focused on maintaining a simple and visually appealing interface that reflected the essence of the handcrafted soap business, ensuring easy navigation and a smooth purchasing process. This project not only enhanced the business's online presence but also provided a seamless shopping experience for their customers, helping drive sales and customer satisfaction.


              </li>
              {/* <li>
                {" "}
                <i className="icon-05"></i>Career Pivot Assistance: For those
                considering a change in their career path, AI.SIW provides
                insightful resources to navigate the complexities of career
                transitions. The platform assesses users' current skills and
                interests and suggests potential new directions. This includes
                information on required qualifications, potential job roles, and
                industries to explore, making the process of pivoting less
                daunting and more informed.
              </li>
              <li>
                {" "}
                <i className="icon-05"></i>Salary Negotiation: Navigating salary
                discussions can be challenging. The platform empowers users with
                tools and strategies for effective salary negotiation. By
                providing market data and benchmarking salaries against industry
                standards, users can approach negotiations with confidence,
                ensuring they advocate for their worth effectively.
              </li>
              <li>
                {" "}
                <i className="icon-05"></i>Interview Preparation: To prepare
                users for interviews, the platform offers a comprehensive suite
                of resources, including mock interviews and feedback sessions.
                Users can practice common interview questions tailored to their
                specific industries, receive tips on body language and
                communication, and learn how to articulate their value
                effectively. This feature aims to equip users with the skills
                necessary to make a positive impression on potential employers.
              </li>
              <li> </li>
              <li>
                {" "}
                <i className="icon-05"></i>Professional Development Planning:
                Lastly, the platform emphasizes continuous professional growth.
                It offers personalized development plans based on users' career
                goals, skill gaps, and industry trends. By focusing on lifelong
                learning, users can remain competitive and adaptable in an
                ever-changing job landscape.
              </li> */}
            </ul>
          </div>

          {/* <div className="project__button">
            <div className="button__one">
              <Link to="http://52.221.211.122/" className="theme-btn theme-btn-one">Preview</Link>
            </div>
            
          </div> */}
        </div>
      </section>
      {/* <div className="project__page p_relative see__pad">
        <div className="auto-container">
          <div className="row">
            {projrctData.map((item, id) => (
              <div className="col-lg-4 col-md-6 col-sm-12" key={id}>
                <div className="portfolio__block p_relative">
                  <div className="portfolio__image">
                    <figure>
                      <img src={item.image} alt="" />
                    </figure>
                  </div>
                  <div className="lower__content p_absolute">
                    <div className="protfolio__text">
                      <div className="text__block">
                        <h4>For more projects</h4>
                        <p>Design</p>
                      </div>
                      <div className="text__block_two">
                       
                      </div>
                    </div>
                    <div className="protfolio__button">
                    <Link to={item.link} className="theme-btn theme-btn-one">
                          Read More<i className="icon-02"></i>
                        </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}
      <FooterOne></FooterOne>
    </>
  );
};

export default Soap;
