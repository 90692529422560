import React, { useState } from "react";
import { pageTitle } from "../PageTitle";
import BreadCrumb from "../BreadCrumb";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import { Link, useLocation } from "react-router-dom";
import two from "../../assets/images/resource/man.png";
import service from "../../assets/images/resource/dataanalyst.jpg";
import basebg from "../../assets/images/resource/base.png";
import g1 from "../../assets/images/resource/DataWare-new.png";
import { Helmet } from "react-helmet-async";

const DataAnalyst = () => {
  pageTitle("Cloudera Data Analyst Course");

  const [formData, setFormData] = useState({
    name: "",

    email: "",

    subject: "",

    phone: "",

    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("Form submitted:", formData);

    setFormData({
      name: "",

      email: "",

      subject: "",

      phone: "",

      message: "",
    });
  };

  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const servicesData = [
    { id: 5, title: "RPA & AI", slug: "/rpa-training" },
    {
      id: 1,
      title: "Cloudera Data Engineering",
      slug: "/data-engineering-course",
    },
    { id: 2, title: "Cloudera Machine Learning", slug: "/machine-learning" },
    { id: 3, title: "Cloudera Data analyst", slug: "/data-analyst" },
    { id: 4, title: "Cloudera Administrator", slug: "/cloud" },
    { id: 6, title: "Just enough Python", slug: "/python" },
  ];

  const renderServices = () => {
    return servicesData.map((service) => (
      <li
        key={service.id}
        className={service.slug === "/Data-Analyst" ? "active" : ""}
      >
        <Link to={service.slug}>{service.title}</Link>
      </li>
    ));
  };

  return (
    <>
      <Helmet>
        <title>Cloudera Data Analyst Course | Learn Big Data Analytics</title>
        <meta
          name="description"
          content="Master big data analytics with our Cloudera Data Analyst course. Learn Apache Hive, Impala, and advanced analytics techniques to become a certified data professional."
        />
        <meta
          name="keywords"
          content="Cloudera Data Analyst Course, Big Data Analytics, Hive, Impala, SQL, Data Warehouse"
        />
        <meta name="author" content="Your Company Name" />
      </Helmet>
      <HeaderOne />

      <section className="page__title p_relative">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${g1})` }}
        ></div>
        <div className="auto-container">
          <div className="content__box p_relative">
            {/* <ul className="bread__crumb">
                    <li><Link to="/">Home</Link></li>
                    {pathnames.map((name, index) => (
                    <li key={index}>
                        <span> -</span>
                        <Link to={`/${pathnames.slice(0, index + 1).join('/')}`}>{name}</Link>
                    </li>
                    ))}
                </ul> */}
            {/* <h1 className="title">{pathnames.length > 0 ? pathnames[pathnames.length - 1] : 'Home'}</h1> */}
          </div>
        </div>
      </section>

      <div className="service__details p_relative">
        <div className="auto-container">
          <div className="row">
            <div className="col-xl-8 col-lg-12 col-md-12">
              <div className="service__right">
                <figure>
                  <img src={service} alt="Service" />
                </figure>

                <h2>
                  <span>Analyzing with Data Warehouse Course Overview</span>
                </h2>

                <p>
                  Join our Analyzing with Data Warehouse course, a four-day
                  intensive program designed to equip you with the skills to
                  apply traditional data analytics and business intelligence
                  techniques to big data. You’ll learn to use tools essential
                  for data professionals, enabling you to access, manipulate,
                  transform, and analyze complex datasets using SQL and familiar
                  scripting languages.
                </p>

                <br />

                <h3>
                  <span>What You’ll Learn</span>
                </h3>

                <ul>
                  <li>
                    Use Apache Hive and Apache Impala to access data through
                    queries.
                  </li>

                  <li>
                    Identify distinctions between Hive and Impala, including
                    differences in syntax, data formats, and supported features.
                  </li>

                  <li>
                    Write and execute queries using functions, aggregate
                    functions, and subqueries.
                  </li>

                  <li>Utilize joins and unions to combine datasets.</li>

                  <li>
                    Create, modify, and delete tables, views, and databases.
                  </li>

                  <li>Load data into tables and store query results.</li>

                  <li>
                    Select file formats and develop partitioning schemes for
                    improved performance.
                  </li>

                  <li>
                    Use analytic and windowing functions to gain insights from
                    your data.
                  </li>

                  <li>Store and query complex or nested data structures.</li>

                  <li>
                    Process and analyze semi-structured and unstructured data.
                  </li>

                  <li>
                    Optimize and extend the capabilities of Hive and Impala.
                  </li>

                  <li>
                    Determine the best choice for your tasks—Hive, Impala, an
                    RDBMS, or a mix of these.
                  </li>

                  <li>
                    Utilize the benefits of the CDP Public Cloud Data Warehouse.
                  </li>
                </ul>

                <br />

                <h3>
                  <span>What to Expect</span>
                </h3>

                <p>
                  This course is designed for aspiring data analysts and
                  professionals seeking to enhance their skills in big data
                  analytics. Participants will gain practical experience through
                  hands-on exercises, preparing them for real-world data
                  challenges.
                </p>

                <br />

                <h3>
                  <span>Course Details</span>
                </h3>

                <ul className="course-details-list">
                  <li>
                    <h5>
                      {" "}
                      <b>1. Foundations for Big Data Analytics</b>{" "}
                    </h5>
                    - Topics covered: Topics include Big Data Analytics
                    Overview, Data Storage: HDFS, Distributed Data Processing:
                    YARN, MapReduce, and Spark.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 2. Introduction to Apache Hive and Impala</b>{" "}
                    </h5>
                    - Topics covered: Understanding Hive, Impala, and their use
                    cases.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>3. Querying with Apache Hive and Impala </b>{" "}
                    </h5>
                    - Topics covered: Databases, tables, query language syntax,
                    and using tools like Hue and Beeline.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>4. Common Operators and Built-In Functions </b>{" "}
                    </h5>
                    - Topics covered: Overview of operators, scalar functions,
                    and aggregate functions.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>5. Analyzing Text </b>{" "}
                    </h5>
                    - Topics covered: Techniques for using regular expressions
                    with Hive and Impala.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 6. Apache Hive Optimization </b>{" "}
                    </h5>
                    - Topics covered: Understanding query performance,
                    cost-based optimization, bucketing, and ORC file
                    optimizations.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 7. Apache Impala Optimization </b>{" "}
                    </h5>
                    - Topics covered: How Impala executes queries and improves
                    performance.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 8. Extending Hive and Impala</b>{" "}
                    </h5>
                    - Topics covered: User-Defined Functions, Parameterized
                    Queries, and Data Management.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 9. Data Storage </b>{" "}
                    </h5>
                    - Topics covered: Creating Databases and Tables, Loading
                    Data, Altering Databases and Tables, Simplifying Queries
                    with Views, Storing Query Results.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 10. Data Storage and Performance</b>{" "}
                    </h5>
                    - Topics covered: Partitioning Tables, Loading Data into
                    Partitioned Tables, When to Use Partitioning, Choosing a
                    File Format, Using Avro and Parquet File Formats.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 11. Working with Multiple Datasets </b>{" "}
                    </h5>
                    - Topics covered: Using UNION, Joins, handling NULL values,
                    and advanced joins.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 12. Analytic Functions and Windowing </b>{" "}
                    </h5>
                    - Topics covered: Using Analytic Functions, Other Analytic
                    Functions, Sliding Windows.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 13. Complex Data </b>{" "}
                    </h5>
                    - Topics covered: Complex Data with Hive, Complex Data with
                    Impala.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 14. Choosing the Best Tool for the Job </b>{" "}
                    </h5>
                    - Topics covered: Comparing Hive, Impala, and relational
                    databases.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 15. CDP Public Cloud Data Warehouse</b>{" "}
                    </h5>
                    - Topics covered: Overview, auto-scaling, and managing
                    virtual warehouses.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 16. Appendix </b>{" "}
                    </h5>
                    - Topics covered: Apache Kudu: What Is Kudu?, Kudu Tables,
                    Using Impala with Kudu.
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-12">
              <div className="service__details__left">
                <div className="sidebar__widget">
                  <div className="all__service">
                    <h4>All Courses</h4>
                  </div>

                  <ul className="service__details__1__cat">
                    {renderServices()}
                  </ul>
                </div>

                <div
                  className="sidebar__widget_network"
                  style={{ backgroundImage: `url(${basebg})` }}
                >
                  <h3>Contact Us For Any Require</h3>

                  <div className="services__icon">
                    <i className="icon-19"></i>
                  </div>

                  <p>Need help? Call us:</p>

                  <h4>+65 90059743</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterOne />
    </>
  );
};

export default DataAnalyst;
