import React from "react";
import { pageTitle } from "../PageTitle";
import BreadCrumb from "../BreadCrumb";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import { Link } from "react-router-dom";
import portfolio1 from "../../assets/images/resource/portfolio-1.png";
import portfolio2 from "../../assets/images/resource/portfolio-2.png";
import portfolio3 from "../../assets/images/resource/portfolio-3.png";
import project from "../../assets/images/resource/project-details-4.jpg";

const Payable = () => {
  pageTitle("Account Payable");
//   const projrctData = [
//     {
//       id: 1,
//       image: portfolio1,
//       link: "/projects-details",
//     },
//     {
//       id: 2,
//       image: portfolio2,
//       link: "/Career-Portal",
//     },
//     {
//       id: 3,
//       image: portfolio3,
//       link: "/svayambu",
//     },
//   ];
  return (
    <>
      <HeaderOne></HeaderOne>
      {/* <BreadCrumb></BreadCrumb> */}
      <section className="project__detail p_relative">
        <div className="auto-container">
          {/* <div className="image">
            <figure>
              <img src={project} alt="" />
            </figure>
          </div>
          <div className="pro__info">
            <ul>
              <li>
                <div className="name">
                  <p>Client :</p>
                </div>
                <div className="name__two">
                  <h4>Jone welliam</h4>
                </div>
              </li>
              <li>
                <div className="name">
                  <p>Category :</p>
                </div>
                <div className="name__two">
                  <h4>Marketing</h4>
                </div>
              </li>
              <li>
                <div className="name">
                  <p>Date :</p>
                </div>
                <div className="name__two">
                  <h4>15 July, 2023</h4>
                </div>
              </li>
            </ul>
          </div> */}
          <div className="data">
            <h4>Account Payable</h4>
          </div>
          {/* <p>
            At AI.SIW, we leverage the power of AI to help you achieve your
            career goals with tailored guidance and advanced tools. Our platform
            offers comprehensive services, including:
          </p> */}
          <div className="data">
            {/* <h4>AI Career Guidance & Tools Hub</h4> */}

          </div>
          <h5><span>Invoice Processing</span></h5>
          <p>
          Automate the updating of invoices in the ERP system, including performing 3-way matching (invoices, purchase orders, and goods receipts). Prevent
          fraud by ensuring payments are made only after successful validations, and then update the status in your ERP accordingly.
          </p>
          <h5><span>Invoice Data Capture</span></h5>
          <p>
          Combine RPA technology with Intelligent Data Capture to scan paper invoices, classify them, extract relevant information, and automatically upload this
          data into your ERP system.
          </p>
          <h5><span>Vendor Setup and Maintenance</span></h5>
          <p>
          Automate the collection and verification of documents needed for vendor setup, and create vendor entries in the enterprise system. Automatically
request updated documents from vendors when current ones expire. For instance, see how a bot can automatically verify ACRA and GST records before
adding a new vendor to the accounting system.
          </p>
          <h5><span>Invoice Creation From Unstructured Data</span></h5>
          <p>
          RPA bots can automatically access and read invoices received via email, retrieve the necessary information, and update the data in your accounting
          system. They can also initiate requests for missing information or create invoices directly through the system.
          </p>
          <h5><span>Payment Processing for Accounts Payable</span></h5>
          <p>
          Automate data entry and payment preparation, including the processing of payments and bulk payment files for journal entries to subsidiary systems.
          </p>

          {/* <div className="project__button">
            <div className="button__one">
              <Link to="#">Previous</Link>
            </div>
            <div className="button__one">
              <Link to="#">Next</Link>
            </div>
          </div> */}
        </div>
      </section>
      {/* <div className="project__page p_relative see__pad">
        <div className="auto-container">
          <div className="row">
            {projrctData.map((item, id) => (
              <div className="col-lg-4 col-md-6 col-sm-12" key={id}>
                <div className="portfolio__block p_relative">
                  <div className="portfolio__image">
                    <figure>
                      <img src={item.image} alt="" />
                    </figure>
                  </div>
                  <div className="lower__content p_absolute">
                    <div className="protfolio__text">
                      <div className="text__block">
                        <h4>For more projects</h4>
                        <p>Design</p>
                      </div>
                      <div className="text__block_two">

                      </div>
                    </div>
                    <div className="protfolio__button">
                      <Link to={item.link} className="theme-btn theme-btn-one">
                        Read More<i className="icon-02"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}
      <FooterOne></FooterOne>
    </>
  );
};

export default Payable;
