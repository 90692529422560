import React, { useState } from "react";

import { pageTitle } from "../PageTitle";

import BreadCrumb from "../BreadCrumb";

import HeaderOne from "../Header/HeaderOne";

import FooterOne from "../Footer/FooterOne";

import { Link, useLocation } from "react-router-dom";

import two from "../../assets/images/resource/man.png";
import { Helmet } from "react-helmet-async";
import service from "../../assets/images/resource/ML.jpeg";

import basebg from "../../assets/images/resource/base.png";
import g1 from "../../assets/images/resource/ML-new.png";

const MachineLearning = () => {
  pageTitle("Cloudera Machine Learning Course");

  const [formData, setFormData] = useState({
    name: "",

    email: "",

    subject: "",

    phone: "",

    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("Form submitted:", formData);

    setFormData({
      name: "",

      email: "",

      subject: "",

      phone: "",

      message: "",
    });
  };

  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const servicesData = [
    { id: 5, title: "RPA & AI", slug: "/rpa-training" },
    {
      id: 1,
      title: "Cloudera Data Engineering",
      slug: "/data-engineering-course",
    },
    { id: 2, title: "Cloudera Machine Learning", slug: "/machine-learning" },
    { id: 3, title: "Cloudera Data analyst", slug: "/data-analyst" },
    { id: 4, title: "Cloudera Administrator", slug: "/cloud" },
    { id: 6, title: "Just enough Python", slug: "/python" },
  ];

  const renderServices = () => {
    return servicesData.map((service) => (
      <li
        key={service.id}
        className={service.slug === "/Machine-Learning" ? "active" : ""}
      >
        <Link to={service.slug}>{service.title}</Link>
      </li>
    ));
  };

  return (
    <>
      <Helmet>
        <title>Cloudera Machine Learning Course - Learn ML with CML</title>
        <meta
          name="description"
          content="Learn machine learning using Cloudera Machine Learning (CML) platform. This course teaches you how to build, deploy, and manage ML models using CML."
        />
        <meta
          name="keywords"
          content="Cloudera, Machine Learning, CML, Data Science, ML Course, Learn Machine Learning"
        />
        <meta
          property="og:title"
          content="Cloudera Machine Learning Course - Learn ML with CML"
        />
        <meta
          property="og:description"
          content="Learn machine learning using Cloudera Machine Learning (CML) platform. This course teaches you how to build, deploy, and manage ML models using CML."
        />
        <meta property="og:image" content={service} />
        <meta property="og:url" content="http://siw.sg/machine-learning" />
      </Helmet>
      <HeaderOne />

      <section className="page__title p_relative">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${g1})` }}
        ></div>
        <div className="auto-container">
          <div className="content__box p_relative">
            {/* <ul className="bread__crumb">
                    <li><Link to="/">Home</Link></li>
                    {pathnames.map((name, index) => (
                    <li key={index}>
                        <span> -</span>
                        <Link to={`/${pathnames.slice(0, index + 1).join('/')}`}>{name}</Link>
                    </li>
                    ))}
                </ul> */}
            {/* <h1 className="title">{pathnames.length > 0 ? pathnames[pathnames.length - 1] : 'Home'}</h1> */}
          </div>
        </div>
      </section>

      <div className="service__details p_relative">
        <div className="auto-container">
          <div className="row">
            <div className="col-xl-8 col-lg-12 col-md-12">
              <div className="service__right">
                <figure>
                  <img src={service} alt="Service" />
                </figure>

                <h2>
                  <span>Overview</span>
                </h2>

                <p>
                  Enterprise data science teams need collaborative access to
                  business data, tools, and computing resources required to
                  develop and deploy machine learning workflows. Cloudera
                  Machine Learning (CML), part of the Cloudera Data Platform
                  (CDP), provides the solution, giving data science teams the
                  required resources.
                </p>

                <p>
                  This four-day course covers machine learning workflows and
                  operations using CML. Participants explore, visualize, and
                  analyze data. You will also train, evaluate, and deploy
                  machine learning models.
                </p>

                <p>
                  The course walks through an end-to-end data science and
                  machine learning workflow based on realistic scenarios and
                  datasets from a fictitious technology company. The
                  demonstrations and exercises are conducted in Python (with
                  PySpark) using CML.
                </p>

                <h2>
                  <span>What you’ll learn</span>
                </h2>

                <p>
                  Through lecture and hands-on exercises, you will learn how to:
                </p>

                <br></br>

                <h3>
                  <span>Course Details</span>
                </h3>

                <ul className="course-details-list">
                  <li>
                    <h5>
                      {" "}
                      <b>1. Introduction to CML </b>{" "}
                    </h5>
                    - Topics covered: Overview, CML Versus CDSW, ML Workspaces,
                    Workspace Roles, Projects and Teams, Settings,
                    Runtimes/Legacy Engines.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>2. Introduction to AMPs and the Workbench</b>{" "}
                    </h5>
                    - Topics covered: Editors and IDE, Git, Embedded Web
                    Applications, AMPs, Data Access and Lineage.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 3. SDX Overview </b>{" "}
                    </h5>
                    - Topics covered: Data Catalog, Authorization, Lineage.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 4. Data Visualization in CML </b>{" "}
                    </h5>
                    - Topics covered: Data Visualization Overview, CDP Data
                    Visualization Concepts, Using Data Visualization in CML.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 5. Experiments </b>{" "}
                    </h5>
                    - Topics covered: Experiments in CML, Introduction to the
                    CML Native Workbench.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>6. Spark Overview </b>{" "}
                    </h5>
                    - Topics covered: How Spark Works, The Spark Stack, File
                    Formats in Spark, Spark Interface Languages, Introduction to
                    PySpark, How Data Frame Operations Become Spark Jobs, How
                    Spark Executes a Job.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 7. Running a Spark Application </b>{" "}
                    </h5>
                    - Topics covered: Running a Spark Application, Reading data
                    into a Spark SQL Data Frame, Examining the Schema of a Data
                    Frame, Computing the Number of Rows and Columns of a Data
                    Frame, Examining a Few Rows of a Data Frame, Stopping a
                    Spark Application.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>8. Inspecting a Spark Data Frame </b>{" "}
                    </h5>
                    - Topics covered: Inspecting a Data Frame, Inspecting a Data
                    Frame Column.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 9. Transforming Data Frames </b>{" "}
                    </h5>
                    - Topics covered: Spark SQL Data Frames, Working with
                    Columns, Working with Rows, Working with Missing Values,
                    Transforming Data Frame Columns.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>10. Spark SQL Data Types </b>{" "}
                    </h5>
                    - Topics covered: Working with Numerical Columns, Working
                    with String Columns, Working with Date and Timestamp
                    Columns, Working with Boolean Columns, Complex Types.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>11. User-Defined Functions </b>{" "}
                    </h5>
                    - Topics covered: Example 1: Hour of Day, Example 2:
                    Great-Circle Distance.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>12. Reading and Writing Data Frames </b>{" "}
                    </h5>
                    - Topics covered: Working with Delimited Text Files, Working
                    with Text Files, Working with Parquet Files, Working with
                    Hive Tables, Working with Object Stores, Working with Pandas
                    Data Frames.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 13. Combining and Splitting Data Frames </b>{" "}
                    </h5>
                    - Topics covered: Joining Data Frames, Splitting a Data
                    Frame.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 14. Summarizing and Grouping Data Frames</b>{" "}
                    </h5>{" "}
                    - Topics covered: Summarizing Data with Aggregate Functions,
                    Grouping Data, Pivoting Data.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 15. Window Functions </b>{" "}
                    </h5>
                    - Topics covered: Example: Cumulative Count and Sum,
                    Example: Compute Average Days Between Rides for Each Rider.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 16. Machine Learning Overview </b>{" "}
                    </h5>
                    - Topics covered: Introduction to Machine Learning, Machine
                    Learning Tools, Apache Spark MLlib.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 17. Exploring and Visualizing Data Frames </b>{" "}
                    </h5>
                    - Topics covered: Possible Workflows for Big Data, Exploring
                    a Single Variable, Exploring a Pair of Variables.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>
                        {" "}
                        18. Monitoring, Tuning, and Configuring Spark
                        Applications
                      </b>{" "}
                    </h5>{" "}
                    - Topics covered: Monitoring Spark Applications, Configuring
                    the Spark Environment.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 19. Fitting and Evaluating Regression Models </b>{" "}
                    </h5>
                    - Topics covered: Assemble the Feature Vector, Fit the
                    Linear Regression Model.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>
                        {" "}
                        20. Fitting and Evaluating Classification Models{" "}
                      </b>{" "}
                    </h5>
                    - Topics covered: Generate Label, Fit the Logistic
                    Regression Model.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>
                        {" "}
                        21. Tuning Algorithm Hyperparameters Using Grid Search{" "}
                      </b>{" "}
                    </h5>
                    - Topics covered: Requirements for Hyperparameter Tuning,
                    Tune the Hyperparameters Using Holdout Cross-Validation,
                    Tune the Hyperparameters Using K-Fold Cross-Validation.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 22. Fitting and Evaluating Clustering Models </b>{" "}
                    </h5>
                    - Topics covered: Print and Plot the Home Coordinates, Fit a
                    Gaussian Mixture Model, Explore the Cluster Profiles.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>
                        {" "}
                        23. Processing Text: Fitting and Evaluating Topic Models{" "}
                      </b>{" "}
                    </h5>{" "}
                    - Topics covered: Fit a Topic Model Using Latent Dirichlet
                    Allocation.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 24. Fitting and Evaluating Recommender Models</b>{" "}
                    </h5>
                    - Topics covered: Recommender Models, Generate
                    Recommendations.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 25. Working with Machine Learning Pipelines </b>{" "}
                    </h5>
                    - Topics covered: Fit the Pipeline Model, Inspect the
                    Pipeline Model.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>
                        {" "}
                        26. Applying a Scikit-Learn Model to a Spark Data Frame{" "}
                      </b>{" "}
                    </h5>{" "}
                    - Topics covered: Build a Scikit-Learn Model, Apply the
                    Model Using a Spark UDF.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>
                        {" "}
                        27. Deploying a Machine Learning Model as a REST API in
                        CML{" "}
                      </b>{" "}
                    </h5>{" "}
                    - Topics covered: Load the Serialized Model, Define a
                    Wrapper Function to Generate a Prediction, Test the
                    Function.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>
                        {" "}
                        28. Auto scaling, Performance, and GPU Settings{" "}
                      </b>{" "}
                    </h5>
                    - Topics covered: Auto scaling Workloads, Working with GPUs.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 29. Model Metrics and Monitoring </b>{" "}
                    </h5>{" "}
                    - Topics covered: Why Monitor Models?, Common Models
                    Metrics, Models Monitoring With Evidently, Continuous Model
                    Monitoring.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>30. Appendix: Workspace Provisioning </b>{" "}
                    </h5>
                    - Topics covered: Workspace and Environment.
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-12">
              <div className="service__details__left">
                <div className="sidebar__widget">
                  <div className="all__service">
                    <h4>All Courses</h4>
                  </div>

                  <ul className="service__details__1__cat">
                    {renderServices()}
                  </ul>
                </div>

                <div
                  className="sidebar__widget_network"
                  style={{ backgroundImage: `url(${basebg})` }}
                >
                  <h3>Contact Us For Any Require</h3>

                  <div className="services__icon">
                    <i className="icon-19"></i>
                  </div>

                  <p>Need help? Call us:</p>

                  <h4>+65 90059743</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterOne />
    </>
  );
};

export default MachineLearning;
