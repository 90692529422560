import React, { useState } from "react";

import { pageTitle } from "../PageTitle";

import BreadCrumb from "../BreadCrumb";

import HeaderOne from "../Header/HeaderOne";

import FooterOne from "../Footer/FooterOne";

import { Link, useLocation } from "react-router-dom";

import two from "../../assets/images/resource/man.png";

import service from "../../assets/images/resource/python.jpg";
import { Helmet } from "react-helmet-async";
import basebg from "../../assets/images/resource/base.png";
import g1 from "../../assets/images/resource/Python-new.png";

const Python = () => {
  pageTitle("Just enough python Course");

  const [formData, setFormData] = useState({
    name: "",

    email: "",

    subject: "",

    phone: "",

    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("Form submitted:", formData);

    setFormData({
      name: "",

      email: "",

      subject: "",

      phone: "",

      message: "",
    });
  };

  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const servicesData = [
    { id: 5, title: "RPA & AI", slug: "/rpa-training" },
    {
      id: 1,
      title: "Cloudera Data Engineering",
      slug: "/data-engineering-course",
    },
    { id: 2, title: "Cloudera Machine Learning", slug: "/machine-learning" },
    { id: 3, title: "Cloudera Data analyst", slug: "/data-analyst" },
    { id: 4, title: "Cloudera Administrator", slug: "/cloud" },
    { id: 6, title: "Just enough Python", slug: "/python" },
  ];

  const renderServices = () => {
    return servicesData.map((service) => (
      <li
        key={service.id}
        className={service.slug === "/Python" ? "active" : ""}
      >
        <Link to={service.slug}>{service.title}</Link>
      </li>
    ));
  };

  return (
    <>
      <Helmet>
        <title>
          Just Enough Python Course - Learn Python Fast and Efficiently
        </title>
        <meta
          name="description"
          content="Join our 'Just Enough Python' course and learn the essentials of Python programming. Perfect for beginners and experienced developers."
        />
        <meta
          name="keywords"
          content="Python course, learn Python, Python for beginners, Python programming, Just Enough Python"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="Just Enough Python Course - Learn Python Fast and Efficiently"
        />
        <meta
          property="og:description"
          content="Join our 'Just Enough Python' course and learn the essentials of Python programming. Perfect for beginners and experienced developers."
        />
        <meta property="og:image" content={g1} />
        <meta property="og:url" content={window.location.href} />
        <meta
          name="twitter:title"
          content="Just Enough Python Course - Learn Python Fast and Efficiently"
        />
        <meta
          name="twitter:description"
          content="Join our 'Just Enough Python' course and learn the essentials of Python programming. Perfect for beginners and experienced developers."
        />
        <meta name="twitter:image" content={g1} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <HeaderOne />

      <section className="page__title p_relative">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${g1})` }}
        ></div>
        <div className="auto-container">
          <div className="content__box p_relative">
            {/* <ul className="bread__crumb">
                    <li><Link to="/">Home</Link></li>
                    {pathnames.map((name, index) => (
                    <li key={index}>
                        <span> -</span>
                        <Link to={`/${pathnames.slice(0, index + 1).join('/')}`}>{name}</Link>
                    </li>
                    ))}
                </ul> */}
            {/* <h1 className="title">{pathnames.length > 0 ? pathnames[pathnames.length - 1] : 'Home'}</h1> */}
          </div>
        </div>
      </section>
      <div className="service__details p_relative">
        <div className="auto-container">
          <div className="row">
            <div className="col-xl-8 col-lg-12 col-md-12">
              <div className="service__right">
                <figure>
                  <img src={service} alt="Service" />
                </figure>
                <h2>
                  <span>Just Enough Python </span>
                </h2>
                <p>
                  Whether you’re new to programming or a seasoned developer
                  looking to pick up a new language, Just Enough Python is the
                  perfect starting point. This guide provides a streamlined,
                  practical approach to Python, giving you the essential skills
                  without overwhelming you with unnecessary details. You’ll
                  learn how to write clean, effective Python code that solves
                  real-world problems, automates tedious tasks, and helps you
                  build projects from scratch.
                  <br></br>
                  <br></br>
                  With Python’s simplicity, readability, and versatility, it has
                  become one of the most popular languages in the world. This
                  guide is designed to help you navigate Python’s most important
                  concepts, including variables, functions, data structures, and
                  libraries, so you can confidently apply them in your own work.
                  Whether you’re aiming to enter the world of data science, web
                  development, or simply automate tasks, Just Enough Python will
                  equip you with the tools and knowledge to get started quickly
                  and efficiently, making it ideal for both beginners and
                  experienced programmers looking to enhance their skills.
                </p>

                <br></br>

                <h3>
                  <span>Course Details</span>
                </h3>

                <ul className="course-details-list">
                  <li>
                    <h5>
                      {" "}
                      <b>1. General Note </b>{" "}
                    </h5>
                    - Topics covered: Exercise Environment Overview, Course
                    Exercises.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>
                        {" "}
                        2. Running Python Scripts and Statements in the REPL{" "}
                      </b>{" "}
                    </h5>
                    - Topics covered: Using the Interpreter, Executing a
                    Program, Executing Statements in the REPL.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>
                        {" "}
                        3. Creating Virtual Environments and Using pip for
                        Managing Dependencies{" "}
                      </b>{" "}
                    </h5>
                    - Topics covered: Creating a Virtual Environment, Installing
                    a Library.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>4. Working with Variables </b>{" "}
                    </h5>
                    - Topics covered: Variables in Python.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>5. Controlling Program Flow </b>{" "}
                    </h5>
                    - Topics covered: Controlling Program Flow Using if,
                    Controlling Program Flow Using while, Controlling Program
                    Flow Using for.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>6. Creating Functions and Modules </b>{" "}
                    </h5>
                    - Topics covered: Creating Functions, Built-in Functions,
                    Modules and Libraries.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 7. Using Data Structures </b>{" "}
                    </h5>
                    - Topics covered: Working with Lists, Working with Tuples,
                    Working with Dictionaries, Working with Sets, Using Flow
                    Statements with Collections.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 8. Working with Files </b>{" "}
                    </h5>
                    - Topics covered: Working with Files.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>9. Essential & Data Science Libraries </b>{" "}
                    </h5>
                    - Topics covered: Using the Python Standard Library, Using
                    numpy for Working with Numerical Data, Using pandas, the
                    Data Analysis Library, Using matplotlib to Visualize Data.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>
                        10. Using Lambda Functions and Method Chaining{" "}
                      </b>{" "}
                    </h5>
                    - Topics covered: Using Lambda Functions, Method Chaining.
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-12">
              <div className="service__details__left">
                <div className="sidebar__widget">
                  <div className="all__service">
                    <h4>All Courses</h4>
                  </div>

                  <ul className="service__details__1__cat">
                    {renderServices()}
                  </ul>
                </div>

                <div
                  className="sidebar__widget_network"
                  style={{ backgroundImage: `url(${basebg})` }}
                >
                  <h3>Contact Us For Any Require</h3>

                  <div className="services__icon">
                    <i className="icon-19"></i>
                  </div>

                  <p>Need help? Call us:</p>

                  <h4>+65 90059743</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterOne />
    </>
  );
};

export default Python;
