import { Fragment } from "react";
import { Link } from "react-router-dom";


export const Services = () => (
    <Fragment>
        {/* <li><Link to="/services">Services</Link></li> */}
        <li><Link to="/RPA-AI">RPA & AI</Link></li>
        <li><Link to="/Whatsapp-estore">WhatsApp Services</Link></li>
        <li><Link to="/Web-Development">Web Development</Link></li>

        <li><Link to="/Data-Engineering">Data Engineering</Link></li>
        <li><Link to="/Data-Science">Data Science</Link></li>

        <li><Link to="/Cloud-Computing">Cloud Computing</Link></li>
    </Fragment>
);
// export const Page = () => (
//     <Fragment>
//         <li><Link to="/projects">Projects</Link></li>
//         <li><Link to="/projects-details">Projects Details</Link></li>
//         <li><Link to="/pricing">Pricing</Link></li>
//         <li><Link to="/team">Team</Link></li>
//         <li><Link to="/testimonial">Testimonial</Link></li>
//         <li><Link to="/error">404 Page</Link></li>
//     </Fragment>
// );
export const News = () => (
    <Fragment>
         <li><Link to="/Rpa-training">RPA & AI</Link></li>
        <li><Link to="/Data-engineering-course">Cloudera Data Engineering</Link></li>
        <li><Link to="/Machine-Learning">Cloudera Machine Learning</Link></li>
        <li><Link to="/Data-analyst">Cloudera Data Analyst</Link></li>
        <li><Link to="/cloud">Cloudera Administrator</Link></li>
        <li><Link to="/python">Just enough Python</Link></li>
        {/* <li><Link to="/blog-details">Whatsapp & openai</Link></li>
        <li><Link to="/rpa-blog">RPA</Link></li>
        <li><Link to="/react-blog">Reactjs</Link></li>
        <li><Link to="/Big-Data">Big Data</Link></li>
        <li><Link to="/Big-Data-pipeline">Big Data pipeline</Link></li> */}
    </Fragment>
);
