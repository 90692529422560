import React from "react";
import { pageTitle } from "../PageTitle";
import BreadCrumb from "../BreadCrumb";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import { Link } from "react-router-dom";
import portfolio1 from "../../assets/images/resource/portfolio-1.png";
import portfolio2 from "../../assets/images/resource/portfolio-2.png";
import portfolio3 from "../../assets/images/resource/portfolio-4.png";
import project from "../../assets/images/resource/project-details-2.jpg";

const Svayambu = () => {
  pageTitle("Svayambu Project");
  const projrctData = [
    {
      id: 1,
      image: portfolio1,
      link: '/projects-details',
    },
    {
      id: 2,
      image: portfolio2,
      link: '/Career-Portal',
    },
    {
      id: 3,
      image: portfolio3,
      link: '/soap-ecommerce',
    },
  ];
  return (
    <>
      <HeaderOne></HeaderOne>
      {/* <BreadCrumb></BreadCrumb> */}
      <section className="project__details p_relative">
        <div className="auto-container">
          <div className="image">
            <figure>
              <img src={project} alt="" />
            </figure>
          </div>
          {/* <div className="pro__info">
            <ul>
              <li>
                <div className="name">
                  <p>Client :</p>
                </div>
                <div className="name__two">
                  <h4>Jone welliam</h4>
                </div>
              </li>
              <li>
                <div className="name">
                  <p>Category :</p>
                </div>
                <div className="name__two">
                  <h4>Marketing</h4>
                </div>
              </li>
              <li>
                <div className="name">
                  <p>Date :</p>
                </div>
                <div className="name__two">
                  <h4>15 July, 2023</h4>
                </div>
              </li>
            </ul>
          </div> */}
          <div className="data">
            <h4><span>Overview of React Website</span></h4>
          </div>
          {/* <p>
            At AI.SIW, we leverage the power of AI to help you achieve your
            career goals with tailored guidance and advanced tools. Our platform
            offers comprehensive services, including:
          </p> */}
          <div className="data">
            {/* <h4>AI Career Guidance & Tools Hub</h4> */}
          </div>
          <p>
          The Bharatanatyam website is a beautifully designed static site dedicated to the classical Indian dance form, Bharatanatyam. It aims to educate visitors about the rich history, cultural significance, and intricate techniques of this art form. The homepage welcomes users with captivating visuals and an engaging introduction to Bharatanatyam, highlighting its origins and evolution.
          </p>
          <div className="project__button">
            <div className="button__one">
              <Link to="https://website.siw.sg/" className="theme-btn theme-btn-one" target='_blank'>Preview</Link>
            </div>
            
          </div>
          <div className="data__list">
            <ul>
              <li>
                {" "}
                <i className="icon-05"></i> Key sections of the website include detailed explanations of the dance's fundamental elements, such as Abhinaya (expression), Nritta (pure dance), and Natyashastra (theory of dance). Additionally, it features tutorials and video demonstrations, making it accessible for beginners. The site also showcases prominent Bharatanatyam dancers and their contributions to the art form, encouraging visitors to appreciate its beauty and intricacies.
              </li>
              <li>
                {" "}
                <i className="icon-05"></i>With an emphasis on community engagement, the website includes information about upcoming performances, workshops, and cultural events. Overall, it serves as a comprehensive resource for enthusiasts and learners alike, fostering a deeper understanding of Bharatanatyam.


              </li>
              {/* <li>
                {" "}
                <i className="icon-05"></i>Career Pivot Assistance: For those
                considering a change in their career path, AI.SIW provides
                insightful resources to navigate the complexities of career
                transitions. The platform assesses users' current skills and
                interests and suggests potential new directions. This includes
                information on required qualifications, potential job roles, and
                industries to explore, making the process of pivoting less
                daunting and more informed.
              </li>
              <li>
                {" "}
                <i className="icon-05"></i>Salary Negotiation: Navigating salary
                discussions can be challenging. The platform empowers users with
                tools and strategies for effective salary negotiation. By
                providing market data and benchmarking salaries against industry
                standards, users can approach negotiations with confidence,
                ensuring they advocate for their worth effectively.
              </li>
              <li>
                {" "}
                <i className="icon-05"></i>Interview Preparation: To prepare
                users for interviews, the platform offers a comprehensive suite
                of resources, including mock interviews and feedback sessions.
                Users can practice common interview questions tailored to their
                specific industries, receive tips on body language and
                communication, and learn how to articulate their value
                effectively. This feature aims to equip users with the skills
                necessary to make a positive impression on potential employers.
              </li>
              <li> </li>
              <li>
                {" "}
                <i className="icon-05"></i>Professional Development Planning:
                Lastly, the platform emphasizes continuous professional growth.
                It offers personalized development plans based on users' career
                goals, skill gaps, and industry trends. By focusing on lifelong
                learning, users can remain competitive and adaptable in an
                ever-changing job landscape.
              </li> */}
            </ul>
          </div>

          {/* <div className="project__button">
            <div className="button__one">
              <Link to="https://website.siw.sg/" className="theme-btn theme-btn-one">Preview</Link>
            </div>
            
          </div> */}
        </div>
      </section>
      {/* <div className="project__page p_relative see__pad">
        <div className="auto-container">
          <div className="row">
            {projrctData.map((item, id) => (
              <div className="col-lg-4 col-md-6 col-sm-12" key={id}>
                <div className="portfolio__block p_relative">
                  <div className="portfolio__image">
                    <figure>
                      <img src={item.image} alt="" />
                    </figure>
                  </div>
                  <div className="lower__content p_absolute">
                    <div className="protfolio__text">
                      <div className="text__block">
                        <h4>For more projects</h4>
                        <p>Design</p>
                      </div>
                      <div className="text__block_two">
                        
                      </div>
                    </div>
                    <div className="protfolio__button">
                    <Link to={item.link} className="theme-btn theme-btn-one">
                          Read More<i className="icon-02"></i>
                        </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}
      <FooterOne></FooterOne>
    </>
  );
};

export default Svayambu;
