import React from "react";
import { Link } from "react-router-dom";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Service = () => {
  const servicesData = [
    {
      id: 1,
      title: "RPA & AI",
      description: "Streamline your operations with Robotic Process Automation (RPA) and Artificial Intelligence (AI). Automate repetitive tasks and gain insights to enhance efficiency and innovation.",
      icon: "icon-robot-1",
      href: "/RPA-AI",
      data_aos_delay: "00ms",
    },
    {
      id: 2,
      title: "WhatsApp Services",
      description: "Transform the way you connect with customers through our cutting-edge WhatsApp E-store Services! Empower your business with a seamless shopping experience.",
      icon1: <FontAwesomeIcon icon={faWhatsapp} />,
      href: "/whatsapp-estore",
      data_aos_delay: "200ms",
    },
    {
      id: 3,
      title: "Web Development",
      description: "We offer custom web development, from simple landing pages to complex solutions, helping clients reach their business goals efficiently.",
      icon: "icon-coding-1",
      href: "/Web-Development",
      data_aos_delay: "200ms",
    },

    {
      id: 4,
      title: "Data Engineering",
      description: "Unlock your data’s potential with SiW. Our data engineering speeds up Cloudera deployment, cutting costs and boosting performance.",
      icon: "icon-square",
      href: "/Data-Engineering",
      data_aos_delay: "400ms",
    },
    {
      id: 5,
      title: "Data Science",
      description: "Unlock insights and drive decisions with our data science services. We analyze complex data sets to help your business thrive.",
      icon: "icon-12",
      href: "/Data-Science",
      data_aos_delay: "00ms",
    },
    {
      id: 6,
      title: "Cloud Computing",
      description: "Leverage the power of Cloud Computing to enhance flexibility, scalability, and cost-efficiency in your operations, enabling seamless access to data and applications from anywhere.",
      icon: "icon-server",
      href: "/Cloud-Computing",
      data_aos_delay: "400ms",
    },
  ];

  return (
    <section className="service__section p_relative">
      <div className="auto-container">
        <div className="service__data">
          <div className="title__data">
            <div className="sub__title">
              <h4>Better Future</h4>
            </div>
            <div className="title">
              <h2 style={{color:"white"}}>Explore Our Services</h2>
            </div>
          </div>
          <div className="row">
            {servicesData.map((item, id) => (
              <div
                className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated"
                data-wow-delay={item.data_aos_delay}
                data-wow-duration="1500ms"
                key={id}
              >
                <div className="service__block">
                  <div className="service__icon">
                    <i className={item.icon}>{item.icon1}</i>
                    <div className="service__icon__two"></div>
                  </div>
                  <div className="service__text">
                    <h4>
                      <Link to={item.href}>{item.title}</Link>
                    </h4>
                    <p>{item.description}</p>
                  </div>
                  <div className="service__button">
                    <Link to={item.href}>
                      <i className="icon-05"></i>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>

  );
};

export default Service;
