import React, { useState } from "react";

import { pageTitle } from "../PageTitle";

import BreadCrumb from "../BreadCrumb";

import HeaderOne from "../Header/HeaderOne";

import FooterOne from "../Footer/FooterOne";

import { Link, useLocation } from "react-router-dom";

import two from "../../assets/images/resource/man.png";

import service from "../../assets/images/resource/de.png";
import { Helmet } from "react-helmet-async";
import basebg from "../../assets/images/resource/base.png";
import g1 from "../../assets/images/resource/DataEngi-new.png";

const DataEngineering = () => {
  pageTitle("Cloudera Data Engineering Course");

  const [formData, setFormData] = useState({
    name: "",

    email: "",

    subject: "",

    phone: "",

    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("Form submitted:", formData);

    setFormData({
      name: "",

      email: "",

      subject: "",

      phone: "",

      message: "",
    });
  };

  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const servicesData = [
    { id: 5, title: "RPA & AI", slug: "/rpa-training" },
    {
      id: 1,
      title: "Cloudera Data Engineering",
      slug: "/data-engineering-course",
    },
    { id: 2, title: "Cloudera Machine Learning", slug: "/machine-learning" },
    { id: 3, title: "Cloudera Data analyst", slug: "/data-analyst" },
    { id: 4, title: "Cloudera Administrator", slug: "/cloud" },
    { id: 6, title: "Just enough Python", slug: "/python" },
  ];

  const renderServices = () => {
    return servicesData.map((service) => (
      <li
        key={service.id}
        className={service.slug === "/Data-engineering-course" ? "active" : ""}
      >
        <Link to={service.slug}>{service.title}</Link>
      </li>
    ));
  };

  return (
    <>
      <Helmet>
        <title>Cloudera Data Engineering Course - Learn Apache Spark</title>
        <meta
          name="description"
          content="Join our Cloudera Data Engineering Course to master Apache Spark, DataFrames, and Hive on the Cloudera Data Platform. Enroll now for hands-on training!"
        />
        <meta
          name="keywords"
          content="Cloudera, Data Engineering, Apache Spark, DataFrames, Hive, Big Data, Data Pipelines"
        />
        <meta name="author" content="SIW" />
      </Helmet>
      <HeaderOne />

      <section className="page__title p_relative ">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${g1})` }}
        ></div>
        <div className="auto-container">
          <div className="content__box p_relative">
            {/* <ul className="bread__crumb">
                    <li><Link to="/">Home</Link></li>
                    {pathnames.map((name, index) => (
                    <li key={index}>
                        <span> -</span>
                        <Link to={`/${pathnames.slice(0, index + 1).join('/')}`}>{name}</Link>
                    </li>
                    ))}
                </ul> */}
            {/* <h1 className="title">{pathnames.length > 0 ? pathnames[pathnames.length - 1] : 'Home'}</h1> */}
          </div>
        </div>
      </section>

      <div className="service__details p_relative">
        <div className="auto-container">
          <div className="row">
            <div className="col-xl-8 col-lg-12 col-md-12">
              <div className="service__right">
                <figure>
                  <img src={service} alt="Service" />
                </figure>

                <h2>
                  <span>Cloudera Data Engineering Course Overview</span>
                </h2>

                <p>
                  This four-day hands-on training course delivers the key
                  concepts and knowledge developers need to utilize Apache Spark
                  for high-performance, parallel applications on the Cloudera
                  Data Platform (CDP). By focusing on Cloudera Data Engineering,
                  participants will gain valuable skills in managing data and
                  building robust data pipelines.
                </p>

                <br></br>

                <h3>
                  <span>What You’ll Learn</span>
                </h3>

                <ul>
                  <li>Distribute, store, and process data in a CDP cluster.</li>

                  <li>
                    Write, configure, and deploy Apache Spark applications.
                  </li>

                  <li>
                    Use Spark interpreters and applications to explore, process,
                    and analyze distributed data.
                  </li>

                  <li>
                    Query data using Spark SQL, DataFrames, and Hive tables.
                  </li>

                  <li>
                    Deploy a Spark application on the Data Engineering Service.
                  </li>
                </ul>

                <br></br>

                <h3>
                  <span>What to Expect</span>
                </h3>

                <p>
                  This course is designed for developers and data engineers.
                  Students should have basic Linux experience, and basic
                  proficiency in either Python or Scala programming. Basic
                  knowledge of SQL is helpful, but prior knowledge of Spark and
                  Hadoop is not required.
                </p>

                <br></br>

                <h3>
                  <span>Course Details</span>
                </h3>

                <ul className="course-details-list">
                  <li>
                    <h5>
                      {" "}
                      <b> 1. HDFS Introduction </b>{" "}
                    </h5>{" "}
                    HDFS Overview, HDFS Components and Interactions, Additional
                    HDFS Interactions, Ozone Overview, and hands-on exercises.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>2. YARN Introduction </b>{" "}
                    </h5>
                    YARN Overview, Components and Interaction, Working with
                    YARN, and hands-on exercises.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 3. Working with RDDs </b>{" "}
                    </h5>
                    Understanding Resilient Distributed Datasets (RDDs) and
                    practical exercises.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 4. Working with DataFrames</b>{" "}
                    </h5>{" "}
                    Introduction to DataFrames, Reading and Writing DataFrames,
                    Working with Columns, Working with Complex Types, Combining
                    and Splitting DataFrames, Summarizing and Grouping
                    DataFrames, Working with UDFs, Working with Windows.
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 5. Introduction to Apache Hive </b>{" "}
                    </h5>
                    About Hive and Transforming data with Hive QL.
                  </li>

                  <h5>
                    <b>6. Working with Apache Hive</b>
                  </h5>
                  <ul>
                    <li>
                      Working with Partitions, Buckets, Skew, and SerDes,
                      Includes exercises on Using Serdes to Ingest Text Data,
                      Using Complex Types to Denormalize Data, Using and Working
                      with Datasets in Scala.
                    </li>
                  </ul>

                  <h5>
                    <b>7. Hive and Spark Integration</b>
                  </h5>
                  <ul>
                    <li>
                      Hive and Spark Integration, Exercise: Spark Integration
                      with Hive.
                    </li>
                  </ul>

                  <h5>
                    <b>8. Distributed Processing Challenges</b>
                  </h5>
                  <ul>
                    <li>Shuffle, Skew, Order.</li>
                  </ul>

                  <h5>
                    <b>9. Spark Distributed Processing</b>
                  </h5>
                  <ul>
                    <li>
                      Spark Distributed Processing, Exercise: Explore Query
                      Execution Order.
                    </li>
                  </ul>

                  <h5>
                    <b>10. Spark Distributed Persistence</b>
                  </h5>
                  <ul>
                    <li>
                      DataFrame and Dataset Persistence, Persistence Storage
                      Levels, Viewing Persisted RDDs, Exercise: Persisting
                      DataFrames.
                    </li>
                  </ul>

                  <h5>
                    <b>11. Data Engineering Service</b>
                  </h5>
                  <ul>
                    <li>
                      Create and Trigger Ad-Hoc Spark Jobs, Orchestrate a Set of
                      Jobs Using Airflow, Data Lineage using Atlas, Auto-scaling
                      in Data Engineering Service.
                    </li>
                  </ul>

                  <h5>
                    <b>12. Workload XM</b>
                  </h5>
                  <ul>
                    <li>
                      Optimize Workloads, Performance, Capacity, Identify
                      Suboptimal Spark Jobs.
                    </li>
                  </ul>

                  <h5>
                    <b>13. Appendix: Working with Datasets in Scala.</b>
                  </h5>
                  <ul style={{ marginBottom: "50px" }}>
                    <li>
                      Working with Datasets in Scala, Exercise: Using Datasets
                      in Scala.
                    </li>
                  </ul>
                </ul>

                {/* <form onSubmit={handleSubmit} className="contact__form">
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Your Name"
                    required
                  />

                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Your Email"
                    required
                  />

                  <input
                    type="text"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    placeholder="Subject"
                    required
                  />

                  <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Phone"
                    required
                  />

                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Message"
                    required
                  />

                  <button type="submit" className="theme-btn">
                    Submit
                  </button>
                </form> */}
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-12">
              <div className="service__details__left">
                <div className="sidebar__widget">
                  <div className="all__service">
                    <h4>All Courses</h4>
                  </div>

                  <ul className="service__details__1__cat">
                    {renderServices()}
                  </ul>
                </div>

                <div
                  className="sidebar__widget_network"
                  style={{ backgroundImage: `url(${basebg})` }}
                >
                  <h3>Contact Us For Any Require</h3>

                  <div className="services__icon">
                    <i className="icon-19"></i>
                  </div>

                  <p>Need help? Call us:</p>

                  <h4>+65 90059743</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterOne />
    </>
  );
};

export default DataEngineering;
