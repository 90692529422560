import React, { useState } from "react";
import { pageTitle } from "../PageTitle";
import BreadCrumb from "../BreadCrumb";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import { Link, useLocation } from "react-router-dom";
import two from "../../assets/images/resource/man.png";
import service from "../../assets/images/resource/rpa.jpg";
import { Helmet } from "react-helmet-async";
import basebg from "../../assets/images/resource/base.png";
import g1 from "../../assets/images/resource/rpa-new.png";
import videoSource from "../../assets/videos/vdo-1.mp4";

const RpaTraining = () => {
  pageTitle("RPA with AI Course");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("Form submitted:", formData);

    setFormData({
      name: "",

      email: "",

      subject: "",

      phone: "",

      message: "",
    });
  };

  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const servicesData = [
    { id: 5, title: "RPA & AI", slug: "/rpa-training" },
    {
      id: 1,
      title: "Cloudera Data Engineering",
      slug: "/data-engineering-course",
    },
    { id: 2, title: "Cloudera Machine Learning", slug: "/machine-learning" },
    { id: 3, title: "Cloudera Data analyst", slug: "/data-analyst" },
    { id: 4, title: "Cloudera Administrator", slug: "/cloud" },
    { id: 6, title: "Just enough Python", slug: "/python" },
  ];

  const renderServices = () => {
    return servicesData.map((service) => (
      <li
        key={service.id}
        className={service.slug === "/Rpa-training" ? "active" : ""}
      >
        <Link to={service.slug}>{service.title}</Link>
      </li>
    ));
  };

  return (
    <>
      <Helmet>
        <title>RPA with AI Course | Learn Robotic Process Automation</title>
        <meta
          name="description"
          content="Enroll in our RPA with AI course to learn the fundamentals of Robotic Process Automation (RPA) and AI. Practical hands-on training with RPA tools and AI-enhanced automation techniques."
        />
        <meta
          name="keywords"
          content="RPA, AI, Robotic Process Automation, Automation Training, AI in Automation, RPA Course"
        />
        <meta
          property="og:title"
          content="RPA with AI Course | Learn Robotic Process Automation"
        />
        <meta
          property="og:description"
          content="Enroll in our RPA with AI course to learn the fundamentals of Robotic Process Automation (RPA) and AI. Practical hands-on training with RPA tools and AI-enhanced automation techniques."
        />
        <meta property="og:image" content={g1} />
        <meta property="og:url" content="https://siw.sg/rpa-training" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="RPA with AI Course | Learn Robotic Process Automation"
        />
        <meta
          name="twitter:description"
          content="Enroll in our RPA with AI course to learn the fundamentals of Robotic Process Automation (RPA) and AI. Practical hands-on training with RPA tools and AI-enhanced automation techniques."
        />
        <meta name="twitter:image" content={g1} />
      </Helmet>
      <HeaderOne />

      <section className="page__title p_relative">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${g1})` }}
        ></div>
        <div className="auto-container">
          <div className="content__box p_relative">
            {/* <ul className="bread__crumb">
                    <li><Link to="/">Home</Link></li>
                    {pathnames.map((name, index) => (
                    <li key={index}>
                        <span> -</span>
                        <Link to={`/${pathnames.slice(0, index + 1).join('/')}`}>{name}</Link>
                    </li>
                    ))}
                </ul> */}
            {/* <h1 className="title">{pathnames.length > 0 ? pathnames[pathnames.length - 1] : 'Home'}</h1> */}
          </div>
        </div>
      </section>
      <div className="service__details p_relative">
        <div className="auto-container">
          <div className="row">
            <div className="col-xl-8 col-lg-12 col-md-12">
              <div className="service__right">
                <figure>
                  <img src={service} alt="Service" />
                </figure>

                <h2>
                  <span>
                    Robotic Process Automation (RPA) with AI Course Overview
                  </span>
                </h2>

                <p>
                  Robotic Process Automation (RPA) with Artificial Intelligence
                  (AI) introduces powerful automation solutions for tasks
                  ranging from rule-based workflows to more complex
                  decision-making processes. RPA automates repetitive tasks,
                  while AI enhances automation with capabilities like language
                  processing, image recognition, and predictive analytics.
                  Together, RPA and AI allow businesses to achieve faster and
                  more intelligent operations across various industries. This
                  four-day hands-on course provides a comprehensive
                  understanding of RPA with AI, covering theoretical concepts,
                  essential skills, and best practices for successful
                  implementation.
                </p>

                <p>
                  Upon completion, participants will have an in-depth
                  understanding of how to leverage RPA and AI in business
                  processes, addressing the integration, deployment, and
                  management of automation. This course covers RPA basics,
                  AI-enhanced automation strategies, tools for development and
                  deployment, and ongoing management practices. Through a series
                  of case studies and practical exercises, participants will
                  gain a broad perspective on real-world applications and how
                  companies globally use RPA with AI to drive efficiency, reduce
                  costs, and improve decision-making capabilities.
                </p>

                <br></br>

                <h3>
                  <span>What You’ll Learn</span>
                </h3>

                <ul>
                  <li>
                    RPA fundamentals and AI-enhanced automation techniques
                  </li>

                  <li>Task and process identification for automation</li>

                  <li>
                    Bot development, deployment strategies, and monitoring for
                    continuous improvement
                  </li>

                  <li>
                    AI models, natural language processing (NLP), and machine
                    learning algorithms designed to enhance RPA workflows
                  </li>
                </ul>

                <br></br>

                <h3>
                  <span>What to Expect</span>
                </h3>

                <p>
                  This course is ideal for aspiring RPA and AI specialists and
                  any professionals looking to expand their understanding of
                  intelligent automation. Participants should have a
                  foundational understanding of programming concepts and some
                  familiarity with data analytics. The course also includes
                  interactive sessions in cloud-hosted environments and provides
                  practical exercises for hands-on learning.
                </p>

                <br></br>

                <h3>
                  <span>Course Details</span>
                </h3>

                <ul>
                  <li>
                    <h5>
                      {" "}
                      <b>
                        1. Introduction to Robotic Process Automation (RPA)
                      </b>{" "}
                    </h5>
                    - Topics covered: What RPA Is and Is Not, RPA vs. Test
                    Automation, UiPath RPA Platform Overview, Power Automate RPA
                    Platform, RPA’s Impact on Outsourcing/Offshoring
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>2. Deeper Understanding of RPA </b>{" "}
                    </h5>{" "}
                    - Topics covered: Industries & Functions Best Suited for
                    Automation, RPA Benefits, Challenges, and Risks, RPA Market
                    Growth & Potential, Overview of Leading RPA Tools, RPA Roles
                    & Job Opportunities
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 3. Implementing and Installing RPA </b>{" "}
                    </h5>
                    - Topics covered: Introduction to Automation Implementation,
                    Ranking RPA Ideas for Value, Using a Simple RPA Idea Ranking
                    Tool, Building a Business Case for Automation, Installing
                    UiPath Studio
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 4. Variables, Arguments, and Data Types </b>{" "}
                    </h5>{" "}
                    - Topics covered: Understanding Variables and Scope, Using
                    Arguments in Workflows, Common Data Types (String, Boolean,
                    Integer), Practical: Creating a Workflow with Variables and
                    Arguments
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>5. Control Flow in UiPath </b>{" "}
                    </h5>{" "}
                    - Topics covered: If-Else and Switch Activities, Looping
                    Constructs: For Each, While, Do While, Loop Control: Break
                    and Continue, Practical: Processing a Data List with
                    Conditions
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 6. UI Automation </b>{" "}
                    </h5>
                    - Topics covered: Basic Concepts of UI Automation, Using
                    Click, Type Into, Get Text, Select Item Activities, Anchor
                    Base and Image Automation, Practical: Automating Form
                    Filling
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 7. Selectors and Wildcards </b>{" "}
                    </h5>
                    - Topics covered: Understanding and Configuring Selectors,
                    Using Wildcards and Variables in Selectors, Practical:
                    Optimizing Selectors for Reliable Automation
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 8. Excel Automation </b>{" "}
                    </h5>
                    - Topics covered: Introduction to Excel Automation, Using
                    Excel Application Scope, Read/Write Range, Append Range,
                    Data Table Manipulation in Excel, Practical: Automating
                    Excel Data Tasks
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b>
                        {" "}
                        9. Advanced Excel Automation & Data Manipulation{" "}
                      </b>{" "}
                    </h5>
                    - Topics covered: Advanced Excel Tasks: Formulas, Filtering,
                    Sorting, Data Table Manipulation (Filter, Join Data Tables),
                    Practical: Complex Data Processing in Excel
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 10. File Handling & Directories </b>{" "}
                    </h5>{" "}
                    - Topics covered: Managing Files and Folders in UiPath,
                    Activities: Copy, Delete, Move Files, Handling Multiple
                    Files and Directories, Practical: Automating File Sorting
                    and Backup
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 11. PDF Automation </b>{" "}
                    </h5>
                    - Topics covered: Introduction to PDF Activities in UiPath,
                    Extracting Text from PDFs (OCR and Non-OCR), Handling
                    Structured Data in PDFs, Practical: Automating Data
                    Extraction from PDFs
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 12. Email Automation </b>{" "}
                    </h5>
                    - Topics covered: Overview of Email Automation (Outlook,
                    SMTP, IMAP, Exchange), Sending/Receiving Emails and
                    Downloading Attachments, Practical: Automating Status Report
                    Emails
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 13. Web Automation </b>{" "}
                    </h5>{" "}
                    - Topics covered: Web Automation Basics, Browser Automation
                    with UiPath (Open Browser, Click, Type Into), Handling
                    Pop-Ups and File Downloads, Practical: Automating Web Data
                    Extraction
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 14. Introduction to Recordings </b>{" "}
                    </h5>{" "}
                    - Topics covered: Types of Recordings (Basic, Web), Benefits
                    of Using Recordings
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 15. Troubleshooting in UiPath </b>{" "}
                    </h5>
                    - Topics covered: Resolving Selector Issues, Handling
                    "Element Not Found" Errors, Addressing Timeout and Data
                    Errors, Debugging Techniques
                  </li>

                  <li>
                    <h5>
                      {" "}
                      <b> 16. Capstone Project</b>{" "}
                    </h5>
                    - Topics covered: Applying RPA Skills in a Real-World
                    Scenario
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-12">
              <div className="service__details__left">
                <div className="sidebar__widget">
                  <div className="all__service">
                    <h4>All Courses</h4>
                  </div>

                  <ul className="service__details__1__cat">
                    {renderServices()}
                  </ul>
                </div>

                <div
                  className="sidebar__widget_network"
                  style={{ backgroundImage: `url(${basebg})` }}
                >
                  <h3>Contact Us For Any Require</h3>

                  <div className="services__icon">
                    <i className="icon-19"></i>
                  </div>

                  <p>Need help? Call us:</p>

                  <h4>+65 90059743</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterOne />
    </>
  );
};

export default RpaTraining;
