import React from "react";
import ScrollToTop from "react-scroll-to-top";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp} from "@fortawesome/free-solid-svg-icons"; // Add faArrowUp for up arrow
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const ScrollTop = () => {
    const handleWhatsAppClick = () => {
        const phoneNumber = "6590059743"; // WhatsApp number (no '+' or spaces)
        const message = "Hello! I would like to know more about your services.";
        const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(url, "_blank");
    };

    return (
        <>
            <ScrollToTop
                smooth
                component={
                    <div
                        style={{
                            background: "white", // Blue background for the up arrow
                            color: "black",
                            // borderRadius: "50%",
                            // width: "50px",
                            // height: "50px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            padding:"12px",
                            borderRadius:"10px"
                        }}
                    >
                        <FontAwesomeIcon icon={faArrowUp} size="lg" />
                    </div>
                }
            />

            {/* WhatsApp Button */}
            <div
                style={{
                    position: "fixed",
                    bottom: "110px",
                    right: "38px",
                    background: "#25D366",
                    color: "white",
                    borderRadius: "50%",
                    // width: "60px",
                    // height: "60px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    padding:"15px",
                    fontSize: "25px"
                }}
                onClick={handleWhatsAppClick}
            >
                <FontAwesomeIcon icon={faWhatsapp} size="lg" />
            </div>
        </>
    );
};

export default ScrollTop;
