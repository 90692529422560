import React from "react";

import { pageTitle } from "../PageTitle";

import BreadCrumb from "../BreadCrumb";

import HeaderOne from "../Header/HeaderOne";

import ServiceFooter from '../Footer/ServiceFooter';

import { Link } from "react-router-dom";

import portfolio1 from "../../assets/images/resource/data-engineering (1).jpg";

import portfolio2 from "../../assets/images/resource/ml (1).jpg";

import portfolio3 from "../../assets/images/resource/data-analyst.jpg";

import portfolio4 from "../../assets/images/resource/adminstrator1.jpg";

import portfolio5 from "../../assets/images/resource/Basics-of-python.png";

import portfolio6 from "../../assets/images/resource/rpa-2.jpg";

import portfolio7 from "../../assets/images/resource/portfolio-07.jpg";

import portfolio8 from "../../assets/images/resource/portfolio-08.jpg";

const Course = () => {
  pageTitle("Courses");

  const projrctData = [
    {
      id: 6,
      image: portfolio6,
      Title: "RPA and Ai",
      link: "/rpa-training",
    },
    {
      id: 1,
      image: portfolio1,
      Title: " Cloudera Data Engineering",
      link: "/data-engineering-course",
    },

    {
      id: 2,
      image: portfolio2,
      Title: "Cloudera Machine Learning",
      link: "/Machine-learning",
    },

    {
      id: 3,
      image: portfolio3,
      Title: "Cloudera Data Analyst",
      link: "/data-analyst",
    },

    {
      id: 4,
      image: portfolio4,
      Title: "Cloudera Administrator",
      link: "/cloud",
    },
    {
      id: 5,
      image: portfolio5,
      Title: "Just enough python",
      link: "/python",
    },
    // {
    //   id: 6,
    //   image: portfolio6,
    //   Title: "RPA and Ai",
    //   link: "/rpa-training",
    // },

    // {
    //   id: 5,

    //   image: portfolio5,
    // },

    // {
    //   id: 6,

    //   image: portfolio6,
    // },

    // {
    //   id: 7,

    //   image: portfolio7,
    // },

    // {
    //   id: 8,

    //   image: portfolio8,
    // },
  ];

  return (
    <>
      <HeaderOne></HeaderOne>

      {/* <BreadCrumb></BreadCrumb> */}

      <div className="project__page p_relative see__pad">
        <div className="row">
          {projrctData.map((item, id) => (
            <div className="col-lg-4 col-md-6 colsm-12" key={id}>
              <div className="portfolio__block p_relative">
                <div className="portfolio__image">
                  <figure>
                    <img src={item.image} alt="" />
                  </figure>
                </div>

                <div className="lower__content p_absolute">
                  <div className="protfolio__text">
                    <div className="text__block">
                      <h4>{item.Title}</h4>
                    </div>
                  </div>

                  <div className="protfolio__button">
                    <Link to={item.link} className="theme-btn theme-btn-one">
                      {" "}
                      Read More<i className="icon-02"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <ServiceFooter></ServiceFooter>
    </>
  );
};

export default Course;
