import React from "react";
import { pageTitle } from "../PageTitle";
import BreadCrumb from "../BreadCrumb";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import { Link } from "react-router-dom";
import portfolio1 from "../../assets/images/resource/portfolio-1.png";
import portfolio2 from "../../assets/images/resource/portfolio-2.png";
import portfolio3 from "../../assets/images/resource/portfolio-3.png";
import project from "../../assets/images/resource/project-details-4.jpg";

const Receivable = () => {
  pageTitle("Account Receivable");
//   const projrctData = [
//     {
//       id: 1,
//       image: portfolio1,
//       link: "/projects-details",
//     },
//     {
//       id: 2,
//       image: portfolio2,
//       link: "/Career-Portal",
//     },
//     {
//       id: 3,
//       image: portfolio3,
//       link: "/svayambu",
//     },
//   ];
  return (
    <>
      <HeaderOne></HeaderOne>
      {/* <BreadCrumb></BreadCrumb> */}
      <section className="project__detail p_relative">
        <div className="auto-container">
          {/* <div className="image">
            <figure>
              <img src={project} alt="" />
            </figure>
          </div>
          <div className="pro__info">
            <ul>
              <li>
                <div className="name">
                  <p>Client :</p>
                </div>
                <div className="name__two">
                  <h4>Jone welliam</h4>
                </div>
              </li>
              <li>
                <div className="name">
                  <p>Category :</p>
                </div>
                <div className="name__two">
                  <h4>Marketing</h4>
                </div>
              </li>
              <li>
                <div className="name">
                  <p>Date :</p>
                </div>
                <div className="name__two">
                  <h4>15 July, 2023</h4>
                </div>
              </li>
            </ul>
          </div> */}
          <div className="data">
            <h4><span>Accounts Receivable</span></h4>
          </div>
          {/* <p>
            At AI.SIW, we leverage the power of AI to help you achieve your
            career goals with tailored guidance and advanced tools. Our platform
            offers comprehensive services, including:
          </p> */}
          <div className="data">
            {/* <h4>AI Career Guidance & Tools Hub</h4> */}
           
          </div>
          <h5><span>Installment Tracking</span></h5>
          <p>
          Track installment due dates and automate the creation and dispatch of installment invoices to customers at preset time intervals.
          </p>
          <h5><span>Credit Approvals</span></h5>
          <p>
          Gather and verify information from various platforms for credit approvals, and automate the generation of required documentation.
          </p>
          <h5><span>Credit Assessment</span></h5>
          <p>
          Extract relevant data from companies' financial statements to create credit assessment profiles, enabling the evaluation of their suitability for programs
such as loans and other financial services.
          </p>
          <h5><span>Customer Master File Maintenance</span></h5>
          <p>
          Automate the collection and verification of documents for creating customer master files in the enterprise system. Automatically follow up on expiring
          documents to obtain updated ones.
          </p>
          <h5><span>Sending of Late Notices</span></h5>
          <p>
          Automatically send late payment notices via email to customers who have overdue payments.
          </p>

          {/* <div className="project__button">
            <div className="button__one">
              <Link to="#">Previous</Link>
            </div>
            <div className="button__one">
              <Link to="#">Next</Link>
            </div>
          </div> */}
        </div>
      </section>
      {/* <div className="project__page p_relative see__pad">
        <div className="auto-container">
          <div className="row">
            {projrctData.map((item, id) => (
              <div className="col-lg-4 col-md-6 col-sm-12" key={id}>
                <div className="portfolio__block p_relative">
                  <div className="portfolio__image">
                    <figure>
                      <img src={item.image} alt="" />
                    </figure>
                  </div>
                  <div className="lower__content p_absolute">
                    <div className="protfolio__text">
                      <div className="text__block">
                        <h4>For more projects</h4>
                        <p>Design</p>
                      </div>
                      <div className="text__block_two">
                      
                      </div>
                    </div>
                    <div className="protfolio__button">
                      <Link to={item.link} className="theme-btn theme-btn-one">
                        Read More<i className="icon-02"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}
      <FooterOne></FooterOne>
    </>
  );
};

export default Receivable;
