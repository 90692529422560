import React, { useState } from "react";
import { pageTitle } from "../PageTitle";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import BreadCrumb from "../BreadCrumb";
import { Link } from "react-router-dom";
import contact from "../../assets/images/resource/contact-1.png";
import map from "../../assets/images/resource/map.jpg";
import axios from "axios";
import Footer from "../Footer/footer";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });

  pageTitle("Contact");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    axios
      .post("https://siw.sg/send-email", formData)
      .then((response) => {
        if (response.data.success) {
          alert("Email sent successfully!");
          setFormData({
            name: "",
            email: "",
            subject: "",
            phone: "",
            message: "",
          });
        }
      })
      .catch((error) => {
        alert("Failed to send email.");
        console.error("Email send error:", error);
      });
  };

  return (
    <>
      <HeaderOne />
      {/* <BreadCrumb /> */}
      <section className="contact__page p_relative see__pad">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="contact__right">
                <div className="form__title">
                  <div className="title__data">
                    <div className="sub__title">
                      <h4>Contact Us</h4>
                    </div>
                    <div className="title">
                      <h2>Get a Free Quote</h2>
                    </div>
                  </div>
                </div>
                <div className="form-inner">
                  <form onSubmit={sendEmail} className="default-form">
                    <div className="row clearfix">
                      <div className="col-xl-6 form-group">
                        <input
                          type="text"
                          name="name"
                          placeholder="Your Name *"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-xl-6 col-lg-12 form-group">
                        <input
                          type="email"
                          name="email"
                          placeholder="Your Email *"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-xl-6 col-lg-12 form-group">
                        <input
                          type="text"
                          name="subject"
                          placeholder="Your Subject"
                          value={formData.subject}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-xl-6 col-lg-12 form-group">
                        <input
                          type="text"
                          name="phone"
                          placeholder="Your Phone"
                          value={formData.phone}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-xl-6 col-lg-12 form-group">
                        <textarea
                          name="message"
                          placeholder="Message"
                          value={formData.message}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-xl-12 form-group message-btn btn-box">
                        <button
                          className="theme-btn theme-btn-one"
                          type="submit"
                        >
                          Send your Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-ms-12">
              <div className="contact__left">
                <figure>
                  <img src={contact} alt="Contact Us" />
                </figure>
                <h4>Contact Information</h4>
                <div className="contact__info">
                  <div className="contact__block">
                    <div className="icon">
                      <i className="icon-19"></i>
                    </div>
                    <div className="contact__text">
                      <Link to="tel:+6580866050">+65 90059743</Link>
                      <br />
                    </div>
                  </div>
                  <div className="contact__block">
                    <div className="icon two">
                      <i className="icon-20"></i>
                    </div>
                    <div className="contact__text">
                      <Link to="mailto:admin@siw.sg">admin@siw.sg</Link>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="google-map-section">
        <div className="auto-container">
          {/* <h2>Find Us Here</h2> */}
          <div className="map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.5678456017085!2d103.83708447714892!3d1.4345307948182602!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da14612a96719f%3A0x49e88e6c6ac6c6d8!2s245%20Yishun%20Ave%209%2C%20%2305%20161%20Block%20Block%20245%2C%20Singapore%20760245!5e0!3m2!1sen!2sin!4v1733742801031!5m2!1sen!2sin"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>

      <FooterOne />
    </>
  );
};

export default Contact;
