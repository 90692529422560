import React, { useState } from 'react';
import { pageTitle } from '../PageTitle';
import BreadCrumb  from '../BreadCrumb';
import HeaderOne from '../Header/HeaderOne';
import ServiceFooter from '../Footer/ServiceFooter';
import { Link } from 'react-router-dom';
import two from "../../assets/images/resource/choose-01.png";
import shape5 from "../../assets/images/shapes/shape-10.png";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Services = () => {
      pageTitle('Services');
      const servicesData = [
        {
          id: 1,
          title: "RPA & AI",
          description: "Streamline your operations with Robotic Process Automation (RPA) and Artificial Intelligence (AI). Automate repetitive tasks and gain insights to enhance efficiency and innovation.",
          icon: "icon-robot-1",
          href: "/RPA-AI",
          data_aos_delay: "00ms",
        },
        {
          id: 2,
          title: "WhatsApp Services",
          description: "Transform the way you connect with customers through our cutting-edge WhatsApp E-store Services! Empower your business with a seamless shopping experience.",
          icon1: <FontAwesomeIcon icon={faWhatsapp} />,
          href: "/whatsapp-estore",
          data_aos_delay: "200ms",
        },
        {
          id: 3,
          title: "Web Development",
          description: "We offer custom web development, from simple landing pages to complex solutions, helping clients reach their business goals efficiently.",
          icon: "icon-coding-1",
          href: "/Web-Development",
          data_aos_delay: "200ms",
        },

        {
          id: 4,
          title: "Data Engineering",
          description: "Unlock your data’s potential with SiW. Our data engineering speeds up Cloudera deployment, cutting costs and boosting performance.",
          icon: "icon-square",
          href: "/Data-Engineering",
          data_aos_delay: "400ms",
        },
        {
          id: 5,
          title: "Data Science",
          description: "Unlock insights and drive decisions with our data science services. We analyze complex data sets to help your business thrive.",
          icon: "icon-12",
          href: "/Data-Science",
          data_aos_delay: "00ms",
        },
        {
          id: 6,
          title: "Cloud Computing",
          description: "Leverage the power of Cloud Computing to enhance flexibility, scalability, and cost-efficiency in your operations, enabling seamless access to data and applications from anywhere.",
          icon: "icon-server",
          href: "/Cloud-Computing",
          data_aos_delay: "400ms",
        },
      ];

      const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
      });



      const handleSubmit = (e) => {
        e.preventDefault();

        // Your logic to handle form submission goes here
        console.log('Form submitted:', formData);

        // Clear the form fields after submission if needed
        setFormData({
          name: '',
          email: '',
          subject: '',
          phone: '',
          message: ''
        });
      };

  return (

    <>
        <HeaderOne></HeaderOne>
        {/* <BreadCrumb></BreadCrumb> */}


        <section className="service__section p_relative">
      <div className="auto-container">
        <div className="service__data">
          <div className="title__data">
            <div className="sub__title">
              <h4>Better Future</h4>
            </div>
            <div className="title">
              <h2 style={{color:"white"}}>Explore Our Services</h2>
            </div>
          </div>
          <div className="row">
            {servicesData.map((item, id) => (
              <div
                className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated"
                data-wow-delay={item.data_aos_delay}
                data-wow-duration="1500ms"
                key={id}
              >
                <div className="service__block">
                  <div className="service__icon">
                    <i className={item.icon}>{item.icon1}</i>
                    <div className="service__icon__two"></div>
                  </div>
                  <div className="service__text">
                    <h4>
                      <Link to={item.href}>{item.title}</Link>
                    </h4>
                    <p>{item.description}</p>
                  </div>
                  <div className="service__button">
                    <Link to={item.href}>
                      <i className="icon-05"></i>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>

        {/* <section className="service__two p_relative">
            <div className="row">
                {servicesDataTwo.map((item, id)=>(
                    <div className="col-xl-3 col-lg-6 col-md-12 wow fadeInUp animated" data-wow-delay={item.data_aos_delay} data-wow-duration="1500ms" key={id}>
                        <div className="service__block__two p_relative">
                            <div className="service__image" style={{ backgroundImage: `url(${shape5})` }}>

                            </div>
                            <div className="service__icon">
                                <div className="service__number">
                                    <h1>{item.number}</h1>
                                </div>
                                <i className={item.icon}></i>
                            </div>
                            <div className="service__text">
                                <h4><Link to={item.href}>{item.title}</Link></h4>
                                <p>{item.description}</p>
                            </div>
                            <div className="service__button__two">
                                <Link to={item.href}>{item.buttontext}</Link>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section> */}
        {/* <div className="service__contact see__pad p_relative">
            <div className="auto-container">
                <div className="service__contact__block">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="service__form__data">
                                <div className="form__title">
                                    <div className="title__data">
                                        <div className="sub__title">
                                            <h4>Contact Us</h4>
                                        </div>
                                        <div className="title">
                                            <h2>Send message</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-inner">
                                    <form onSubmit={handleSubmit} method="post" action="contact.php" className="default-form">
                                        <div className="row clearfix">
                                            <div className="col-xl-6 form-group">
                                                <input type="name" name="name" placeholder="Your Name *" required="" />
                                            </div>
                                            <div className="col-xl-6 col-lg-12 form-group">
                                                <input type="email" name="email" placeholder="Your Email *" required="" />
                                            </div>
                                            <div className="col-xl-6 col-lg-12 form-group">
                                                <input type="text" name="subject" placeholder="Your Subject " required="" />
                                            </div>
                                            <div className="col-xl-6 col-lg-12 form-group">
                                                <input type="text" name="phone" placeholder="Your Phone " required="" />
                                            </div>
                                            <div className="ccol-xl-6 col-lg-12 form-group">
                                                <textarea name="message" placeholder="Message"></textarea>
                                            </div>
                                            <div className="col-xl-12 form-group message-btn btn-box">
                                                <button className="theme-btn theme-btn-one" type="submit" name="submit-form">Send your Message</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 p_relative">
                            <div className="service__contact__img">
                                <figure>
                                    <img src={two} alt=" /" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        <ServiceFooter></ServiceFooter>
    </>
  );
};

export default Services;
