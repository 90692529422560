import React from "react";
import { pageTitle } from "../PageTitle";
import BreadCrumb from "../BreadCrumb";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import { Link } from "react-router-dom";
import portfolio1 from "../../assets/images/resource/portfolio-1.png";
import portfolio2 from "../../assets/images/resource/portfolio-2.png";
import portfolio3 from "../../assets/images/resource/portfolio-3.png";
import project from "../../assets/images/resource/project-details-4.jpg";

const report = () => {
  pageTitle("Reporting");
  //   const projrctData = [
  //     {
  //       id: 1,
  //       image: portfolio1,
  //       link: "/projects-details",
  //     },
  //     {
  //       id: 2,
  //       image: portfolio2,
  //       link: "/Career-Portal",
  //     },
  //     {
  //       id: 3,
  //       image: portfolio3,
  //       link: "/svayambu",
  //     },
  //   ];
  return (
    <>
      <HeaderOne></HeaderOne>
      {/* <BreadCrumb></BreadCrumb> */}
      <section className="project__detail p_relative">
        <div className="auto-container">
          {/* <div className="image">
            <figure>
              <img src={project} alt="" />
            </figure>
          </div>
          <div className="pro__info">
            <ul>
              <li>
                <div className="name">
                  <p>Client :</p>
                </div>
                <div className="name__two">
                  <h4>Jone welliam</h4>
                </div>
              </li>
              <li>
                <div className="name">
                  <p>Category :</p>
                </div>
                <div className="name__two">
                  <h4>Marketing</h4>
                </div>
              </li>
              <li>
                <div className="name">
                  <p>Date :</p>
                </div>
                <div className="name__two">
                  <h4>15 July, 2023</h4>
                </div>
              </li>
            </ul>
          </div> */}
          <div className="data">
            <h4>
              <span>Financial Reporting & Reviews</span>
            </h4>
          </div>
          {/* <p>
            At AI.SIW, we leverage the power of AI to help you achieve your
            career goals with tailored guidance and advanced tools. Our platform
            offers comprehensive services, including:
          </p> */}
          <div className="data">
            {/* <h4>AI Career Guidance & Tools Hub</h4> */}
          </div>
          <h5>
            <span>Financial Review Preparation</span>
          </h5>
          <p>
            Automate the creation of management reports or slides by collating
            information from various finance management systems and reports. For
            example, a bot can extract data from a website, transfer it to
            Excel, and format it as needed. While this demo shows a simple
            process, it can be scaled to collect data from multiple sources
            based on your requirements.
          </p>
          <h5>
            <span>Financial Reporting</span>
          </h5>
          <p>
          Aggregate information from various systems to create comprehensive financial reports, reducing delays and errors caused by switching between
          different systems.
          </p>
          <h5>
            <span>Scorecards & Dashboards for Performance Tracking</span>
          </h5>
          <p>
          Collect data from various sources to create dashboards and scorecards, enabling analysis of performance over multiple periods.
          </p>
          <h5>
            <span>Compliance & Discrepancy</span>
          </h5>
          <p>
          Extract information from bank statements and compare it with company records in your enterprise systems. Validate the data against business rules to
          identify any discrepancies.
          </p>
          {/* <h5>
            <span>Sending of Late Notices</span>
          </h5>
          <p>
            Automatically send late payment notices via email to customers who
            have overdue payments.
          </p> */}

          {/* <div className="project__button">
            <div className="button__one">
              <Link to="#">Previous</Link>
            </div>
            <div className="button__one">
              <Link to="#">Next</Link>
            </div>
          </div> */}
        </div>
      </section>
      {/* <div className="project__page p_relative see__pad">
        <div className="auto-container">
          <div className="row">
            {projrctData.map((item, id) => (
              <div className="col-lg-4 col-md-6 col-sm-12" key={id}>
                <div className="portfolio__block p_relative">
                  <div className="portfolio__image">
                    <figure>
                      <img src={item.image} alt="" />
                    </figure>
                  </div>
                  <div className="lower__content p_absolute">
                    <div className="protfolio__text">
                      <div className="text__block">
                        <h4>For more projects</h4>
                        <p>Design</p>
                      </div>
                      <div className="text__block_two">
                      
                      </div>
                    </div>
                    <div className="protfolio__button">
                      <Link to={item.link} className="theme-btn theme-btn-one">
                        Read More<i className="icon-02"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}
      <FooterOne></FooterOne>
    </>
  );
};

export default report;
