import React, { useState } from "react";
import { pageTitle } from "../PageTitle";
import BreadCrumb from "../BreadCrumb";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import { Link, useLocation } from "react-router-dom";
import two from "../../assets/images/resource/choose-01.png";
import service from "../../assets/images/resource/web development.jpg";
import basebg from "../../assets/images/resource/base.png";
import g1 from "../../assets/images/resource/we-dev.png";
import videoSource from "../../assets/videos/vdo-5.mp4";

const DigitalMarketing = () => {
  pageTitle("Web development");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Your logic to handle form submission goes here
    console.log("Form submitted:", formData);

    // Clear the form fields after submission if needed
    setFormData({
      name: "",
      email: "",
      subject: "",
      phone: "",
      message: "",
    });
  };

  const servicesData = [
    { id: 1, title: "RPA & AI", slug: "/RPA-AI" },
    { id: 2, title: "Whatsapp Services", slug: "/Whatsapp-estore" },
    { id: 3, title: "Web Development", slug: "/Web-Development" },
    { id: 4, title: "Data Engineering", slug: "/Data-Engineering" },
    { id: 5, title: "Data Science", slug: "/Data-Science" },
    { id: 6, title: "Cloud-Computing", slug: "/Cloud-Computing" },
  ];

  const renderServices = () => {
    return servicesData.map((service) => (
      <li
        key={service.id}
        className={service.slug === "/digital-marketing" ? "active" : ""}
      >
        <Link to={service.slug}>{service.title}</Link>
      </li>
    ));
  };

  return (
    <>
      <HeaderOne></HeaderOne>
      {/* <BreadCrumb></BreadCrumb> */}
      <section className="page__title p_relative">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${g1})` }}
        ></div>
        <div className="auto-container">
          <div className="content__box p_relative">
            {/* <ul className="bread__crumb">
                    <li><Link to="/">Home</Link></li>
                    {pathnames.map((name, index) => (
                    <li key={index}>
                        <span> -</span>
                        <Link to={`/${pathnames.slice(0, index + 1).join('/')}`}>{name}</Link>
                    </li>
                    ))}
                </ul> */}
            {/* <h1 className="title">{pathnames.length > 0 ? pathnames[pathnames.length - 1] : 'Home'}</h1> */}
          </div>
        </div>
      </section>
      <div className="service__details p_relative ">
        <div className="auto-container">
          <div className="row">
            <div className="col-xl-8 col-lg-12 col-md-12">
              <div className="service__right">
                <figure>
                  <img src={service} alt="" />
                </figure>
                <h2>Building Innovative Web Solutions for the Digital Era. </h2>
                <p>
                  <h3>
                    <span>
                      Seamless Integration of React application with OpenAPI and Single
                      Sign-On
                    </span>
                  </h3>
                  Our web Application's React-based service integrates
                  seamlessly with an OpenAPI interface to deliver a dynamic and
                  user-centric experience. The system is designed to provide
                  secure and efficient access through a single sign-on (SSO)
                  mechanism, ensuring that users can authenticate themselves
                  effortlessly across multiple services without needing to
                  repeatedly log in. By leveraging OpenAPI, our platform ensures
                  robust interoperability, enabling smooth communication between
                  various components and external APIs, whether for fetching
                  real-time data, updating user preferences, or integrating
                  third-party tools. React’s dynamic and component-based
                  architecture ensures that the user interface remains highly
                  responsive and visually appealing, adapting intuitively to
                  different user needs. The SSO functionality streamlines the
                  user experience by reducing login friction while maintaining a
                  high level of security through encrypted token-based
                  authentication, making the platform not only user-friendly but
                  also trustworthy. This setup allows developers to scale and
                  maintain the service efficiently, offering users a seamless
                  and cohesive interaction with all the tools and features
                  available on the web Application.
                </p>
                <h3>
                  <span>e-Commerce Website Development</span>{" "}
                </h3>
                <p>
                  Create an interactive, robust and user-friendly multi-vendor
                  e- commerce store for your retail business. Sell products
                  worldwide and increase revenue upto 10 times with your own
                  e-commerce marketplace. Our dedicated team of eCommerce
                  experts support businesses to build a customer-centric eStores
                  and enhance user experience with the help of advanced
                  technologies, omnichannel presence, and customized solutions.
                </p>
                <p>
                  <h3>
                    <span>Responsive Web Application Development</span>
                  </h3>
                  Building attractive & customised responsive websites thats
                  works on any devices. We aim to provide the best user mobile
                  and web experiences for our customers. Beautiful website. SEO
                  implementation. Customised Design. Many Singaporeans today
                  surf the Internet on their phones. We make websites
                  mobile-responsive so your buyers can purchase from you any
                  time, any day, and anywhere!
                </p>
                {/* <p>
                  <h3>
                    <span>Web Application Development</span>
                  </h3>
                  We convert your website to an android mobile application and
                  send you the app (.apk file) ready to launch. We convert your
                  website to an iOs mobile application and send you the app
                  ready to launch, so you can submit it to the App Store. How it
                  works Submit your website URL | We deliver your app | Upload
                  to App Store or Play Store
                </p> */}
                <p>
                  <h3>
                    <span>Web Application Maintenance</span>
                  </h3>
                  Website maintenance is the most effective and cost- efficient
                  when it's done continuously. 1. Review your website design and
                  structure - can be it improved? 2. Review SEO and meta titles
                  and descriptions to ensure they are as effective as possible
                  3. Test and tweak popups, forms, and calls to action 4. Review
                  your workload for efficiencies to see if anything can be
                  automated 5. Test your website on all devices and browsers to
                  see if it displays correctly
                </p>
                <p style={{ marginBottom: "50px" }}>
                  <h3>
                    <span>Mobile App Development</span>
                  </h3>
                  We convert your website to an android mobile application and
                  send you the app (.apk file) ready to launch. We convert your
                  website to an iOs mobile application and send you the app
                  ready to launch, so you can submit it to the App Store. How it
                  works Submit your website URL | We deliver your app | Upload
                  to App Store or Play Store.
                </p>
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="/responsive" target="_blank">
                        <i className="icon-04"></i> Responsive Design
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="/performance" target="_blank">
                        <i className="icon-04"></i> Performance Optimization
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="/seo" target="_blank">
                        <i className="icon-04"></i>SEO-Friendly Architecture{" "}
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="/security" target="_blank">
                        <i className="icon-04"></i> Security
                      </Link>
                    </li>
                  </div>
                </div>
                {/* <h3><span>Enterprise Resource Planning (ERP) software</span></h3>
                <p>
                  An Enterprise resource planning (ERP) software or system
                  development helps organizations better manage their resources.
                  The most vital aim to be achieved through ERP software is to
                  integrate back office business processes and facilitating the
                  information flow within an organization to take data-driven
                  decisions. So enterprise resources planning software / system
                  collects data from various levels of an organization and
                  organizes it for business activities across departments. With
                  a dedicated enterprise resource planning software / system
                  development, an organization can standardize and automate its
                  processes to achieve high level of efficiency in its
                  operations. The ERP system ensures that all employees in an
                  organization are working with the same data and watching the
                  same key-performance indicators.
                </p>
                <p style={{marginBottom:"50px"}}>
                  <h3><span>Real time monitoring system</span></h3>
                  Technology failure means business failure. Our RMS provides
                  peace of mind by monitoring your processes, applications, and
                  infrastructure in real-time, and alerting or taking action
                  when a problem is detected. RMS provides smarter monitoring
                  for your infrastructure and applications across cloud,
                  containerised and orchestrated environments. By utilising the
                  hundreds of plugins, RMS provides highly customisable
                  Enterprise grade solutions designed for low latency, time
                  critical, secure environments. We deliver operational
                  resilience across your complex technology stack in order to
                  keep the lights on.
                </p> */}
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-12">
              <div className="service__details__left">
                <div
                  className="sidebar__widget_network"
                  style={{ backgroundImage: `url(${basebg})` }}
                >
                  {/* <h3>WhatsApp Services</h3> */}
                  <video
                    controls
                    style={{ width: "100%", marginRight: "10px" }}
                  >
                    <source src={videoSource} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className="sidebar__widget">
                  <div className="all__service">
                    <h4>All Services</h4>
                  </div>

                  <ul className="service__details__1__cat">
                    {renderServices()}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="service__contact see__pad p_relative">
        <div className="auto-container">
          <div className="service__contact__block">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="service__form__data">
                  <div className="form__title">
                    <div className="title__data">
                      <div className="sub__title">
                        <h4>Contact Us</h4>
                      </div>
                      <div className="title">
                        <h2>Send message</h2>
                      </div>
                    </div>
                  </div>
                  <div className="form-inner">
                    <form
                      onSubmit={handleSubmit}
                      method="post"
                      action="contact.php"
                      className="default-form"
                    >
                      <div className="row clearfix">
                        <div className="col-xl-6 form-group">
                          <input
                            type="name"
                            name="name"
                            placeholder="Your Name *"
                            required=""
                          />
                        </div>
                        <div className="col-xl-6 col-lg-12 form-group">
                          <input
                            type="email"
                            name="email"
                            placeholder="Your Email *"
                            required=""
                          />
                        </div>
                        <div className="col-xl-6 col-lg-12 form-group">
                          <input
                            type="text"
                            name="subject"
                            placeholder="Your Subject "
                            required=""
                          />
                        </div>
                        <div className="col-xl-6 col-lg-12 form-group">
                          <input
                            type="text"
                            name="phone"
                            placeholder="Your Phone "
                            required=""
                          />
                        </div>
                        <div className="ccol-xl-6 col-lg-12 form-group">
                          <textarea
                            name="message"
                            placeholder="Message"
                          ></textarea>
                        </div>
                        <div className="col-xl-12 form-group message-btn btn-box">
                          <button
                            className="theme-btn theme-btn-one"
                            type="submit"
                            name="submit-form"
                          >
                            Send your Message
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 p_relative">
                <div className="service__contact__img">
                  <figure>
                    <img src={two} alt=" /" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <FooterOne></FooterOne>
    </>
  );
};

export default DigitalMarketing;
